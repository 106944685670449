import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { commonService } from '../Helpers/commonService.helper';
import { toast } from 'react-toastify';
// Included by sanjana DATE:12-03-2024
import CryptoJS from "crypto-js";
// End of DATE:12-03-2024

//SOP-11 - TS (Testing Point -01) - start
//Need Eye visible on the right side of the password and confirm password text box                  
import { Visibility, VisibilityOff } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
//SOP-11 - TS (Testing Point -01) - end

// Included by sanjana SOP-41 Imports
import OtpInput from 'react-otp-input';
// End of SOP-41

const HomeSignUpPage =({handleSignUpClose})=>{


    const [countryOptions, setCountryOptions]=useState([]);
    const [selectedCountryId, setSelectedCountryId] = useState(null);
    const [CompanyNameError, setCompanyNameError]=useState(false)
    const [CompanyNameValue, setCompanyNameValue]=useState('')
    const [CompanyNameErrorMessage, setCompanyNameErrorMessage]=useState(false)
    const [UserNameError, setUserNameError]=useState(false)
    const [UserNameValue, setUserNameValue]=useState('')
    const [UserNameErrorMessage, setUserNameErrorMessage]=useState(false)
    const [EmailError, setEmailError]=useState(false)
    const [EmailValue, setEmailValue]=useState('')
    const [EmailErrorMessage, setEmailErrorMessage]=useState(false)    
    const [mobileError, setMobileError]=useState(false)
    const [mobileValue, setMobileValue]=useState('')
    const [mobileErrorMessage, setMobileErrorMessage]=useState(false)  
    const [PasswordError, setPasswordError]=useState(false)
    const [PasswordValue, setPasswordValue]=useState('')
    const [PasswordErrorMessage, setPasswordErrorMessage]=useState(false)      
    const [ConfirmPasswordError, setConfirmPasswordError]=useState(false)
    const [ConfirmPasswordValue, setConfirmPasswordValue]=useState('')
    const [ConfirmPasswordErrorMessage, setConfirmPasswordErrorMessage]=useState(false)
    const [CountryError, setCountryError]=useState(false)
    const [CountryErrorMessage, setCountryErrorMessage]=useState(false)
    // Included by sanjana SOP-41 useState
    const [signUpOTPOpen , setsignUpOTPOpen]= useState(false)
    const [OTPValue, setOTPValue] = useState('');
    const [timer, setTimer] = useState("00:00:00");
    const Ref = useRef(null);
    let OTP;
    const [ResendDisabel, setResendDisabel] = useState(true)
    //   End ond SOP-41


    //SOP-11 - TS (Testing Point -01) - start
    //Need Eye visible on the right side of the password and confirm password text box
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    // Included by sanjana SOP-41 to get OTP form session storage
    const GlobalOTPFromSession = sessionStorage.getItem("GlobalOTP");
    // End of SOP-41


    //SOP-79 Included by PCS [send new registration as email to sales team- decrypting from session]
    const GlobalSalesteamEmailsession = sessionStorage.getItem("SalesTeamEmail");

    //To decrypt data from session..
        //Secret pass Used to encrypt and decrypt
            const secretPass = "XkhZG4fW2t2W";   
            let decryptedSalesEmailFromSession; 
                if (GlobalSalesteamEmailsession && typeof GlobalSalesteamEmailsession === 'string' && GlobalSalesteamEmailsession.trim() !== '') {
                    const bytes = CryptoJS.AES.decrypt(GlobalSalesteamEmailsession, secretPass);
                    decryptedSalesEmailFromSession = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                } 
            // console.log(decryptedSalesEmailFromSession)
    //EOF SOP-79 Included by PCS [send new reg as email to sales team]



    //SOP-79 Included By PCS [to get sales team email from DB]
    const [adminEmail, setAdminEmail]=useState('')// Initializing sales team email
    const getIntopzSalesEmail = () => 
           {
            commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/intopzadmin/get_intopz_admin_email`)
                .then((res) => {
                    if (res.status === 200) {
                        setAdminEmail(res.data.data[0].admin_email)
                        let salesEmail =res.data.data[0].admin_email
                        const datasalesEmail = CryptoJS.AES.encrypt(JSON.stringify(salesEmail),secretPass).toString();
                        sessionStorage.setItem("SalesTeamEmail",datasalesEmail) 
                                            }
                                })
                .catch((error) => {
                        console.error("Error:", error);
                                });
             }
 //EOF SOP-79 Included By PCS [to get sales team email from DB]


    const handleTogglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };
    
    const handleTogglePasswordVisibilityConfirm = () => {
        setShowPasswordConfirm((prev) => !prev);
    };
    //SOP-11 - TS (Testing Point -01) - end

    const handleSingUpPageClose = () => {

        if (handleSignUpClose && typeof handleSignUpClose === 'function') {
            handleSignUpClose(); // Call the provided function
            //console.log("handleSignUpClose && typeof handleSignUpClose === 'function'",handleSignUpClose && typeof handleSignUpClose === 'function')
        }
    };

    // Included by sanjana SOP-41 dialouge box open and close
    const handleSignUpOTPOpen = () => {
        setsignUpOTPOpen(true)
    };

    const handleSignUpOTPClose = () => {
        setsignUpOTPOpen(false)
    };
    // End of SOP-41


    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.name,
      });
    
     const GetCountryList = async () => {
       
        try {
          const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/listcountry`);
          //console.log("Country" ,res.data.data);
          let formattedCountry = res.data.data.map((item) => {
            const { countryid, countryname } = item;
            return {
              name: countryname,
              value: countryid,
            };
          });
          setCountryOptions(formattedCountry)
        } catch (err) {
          //console.log( "co",err);
        }
      };

    
    
      const handleCompanyNameChange = (event) => {
        const trimmedCompanyNameValue = event.target.value.trim();
        setCompanyNameValue(event.target.value);
        if (trimmedCompanyNameValue === '') {
            setCompanyNameError(true);
            setCompanyNameErrorMessage("Company name is required");
        } 
        else if( trimmedCompanyNameValue.length >225 ){
            setCompanyNameError(true);
            setCompanyNameErrorMessage("Max 255 characters");
        }
        else {
            setCompanyNameError(false);
            setCompanyNameErrorMessage("");
        }
    };
    
    
    
    
    const handleUserNameChange = (event) => {
      const trimmedUserNameValue = event.target.value.trim();
      setUserNameValue(event.target.value);
      if (trimmedUserNameValue === '') {
          setUserNameError(true);
          setUserNameErrorMessage("Name of the person is required");
      } 
      else if( trimmedUserNameValue.length >255 ){
          setUserNameError(true);
          setUserNameErrorMessage("Max 255 characters");
      }
      else if( !/^[A-Za-z\s][A-Za-z.\s]*$/.test(trimmedUserNameValue) ){
          setUserNameError(true);
          setUserNameErrorMessage("Alphabets only");
      }
      else {
          setUserNameError(false);
          setUserNameErrorMessage("");
      }
    };
    
    
    
    const handleEmailChange = (event) => {
      const trimmedEmailValue = event.target.value.trim();
      setEmailValue(event.target.value);
      if (trimmedEmailValue === '') {
          setEmailError(true);
          setEmailErrorMessage("Email is required");
      } 
      else if( trimmedEmailValue.length >255 ){
          setEmailError(true);
          setEmailErrorMessage("Max 255 characters");
      }
      else if( !(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(trimmedEmailValue) ){
          setEmailError(true);
          setEmailErrorMessage("Invalid Email");
      }
      else {
          setEmailError(false);
          setEmailErrorMessage("");
      }
    //   GetCompanyExistEmail();
    };
    
    
    
    
    const handleMobileChange = (event) => {
        const trimmedMobileValue = event.target.value.trim();
        setMobileValue(event.target.value);
        if (trimmedMobileValue === '') {
            setMobileError(true);

            //SOP-11 - modified TS (Testing feedback points -17)
            //Field name:Mobile  while save without mobile then getting a alert message "Mobile number is required".To follow unformity field name to be rename  as "Mobile number"
            // setMobileErrorMessage("Mobile number is required");
            setMobileErrorMessage("Mobile is required");

        } 
        else if( trimmedMobileValue.length >15 ){
            setMobileError(true);
            setMobileErrorMessage("Invalid mobile number");
        }
            //SOP-11 - TS - (Testing feedback point -07)
            //It does not allow to enter + in mobile number field,If user needs to enter a country code,so it to be allowed
            // else if( !/^[0-9]+$/.test(trimmedMobileValue) ){
            else if( !/^[+0-9]+$/.test(trimmedMobileValue) ){

            setMobileError(true);

            //SOP-11 - TS - (Testing feedback point -07)
            //It does not allow to enter + in mobile number field,If user needs to enter a country code,so it to be allowed
            // setMobileErrorMessage("Numbers only");
            setMobileErrorMessage("Numbers and '+' sign only");
        }
        else {
            setMobileError(false);
            setMobileErrorMessage("");
        }
    };
    
    
    
    const handlePasswordChange = (event) => {
      const trimmedPasswordValue = event.target.value.trim();
      setPasswordValue(event.target.value);

      if (ConfirmPasswordValue !== '') {
        if (trimmedPasswordValue !== ConfirmPasswordValue) {
            setConfirmPasswordError(true);
            
            //SOP-11 - modified by TS - (Testing feedback points -12)
            //While submit with wrong confirm password will get  alert message as  "Password must match" and it to be rename as [Confirm password is not matching]
            //setConfirmPasswordErrorMessage("Passwords must match");
            setConfirmPasswordErrorMessage("Confirm password is not matching");

        } else {
          setConfirmPasswordError(false);
          setConfirmPasswordErrorMessage("");
        }
      }

       //SOP-11 - Ts (Testing Feedback points -9 & 10) -start
       //IF user create a week password like:12345,then need to suggest(Alert message to display -Enter a strong password ]like combination with alphanumeric "Test@123" 
       //  regular expression 
       const strongPasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;
        
      if (trimmedPasswordValue === '') {
          setPasswordError(true);
          setPasswordErrorMessage("Password is required");
      }
       //SOP-11 - Ts (Testing Feedback points -9 & 10)
       //IF user create a week password like:12345,then need to suggest(Alert message to display -Enter a strong password ]like combination with alphanumeric "Test@123" 
        else if (!strongPasswordRegex.test(trimmedPasswordValue)) {
            setPasswordError(true);
            // Modified by sanjana SOP-41 to change Test@123 as Must contains atleast one character, number & special character
            // setPasswordErrorMessage("Enter a strong password (e.g., Test@123)");
            setPasswordErrorMessage("Enter a strong password (Must contains atleast one character, number & special character)");
            // End of SOP-41
        } 
       //SOP-11 - Ts (Testing Feedback points -9 & 10) - end
       
      else if( trimmedPasswordValue.length >12 ){
          setPasswordError(true);
          setPasswordErrorMessage("Max 12 characters");
      }
        else {
          setPasswordError(false);
          setPasswordErrorMessage("");
      }
    };
    
    
    
    
    const handleConfirmPasswordChange = (event) => {
      const trimmedConfirmPasswordValue = event.target.value.trim();
      setConfirmPasswordValue(event.target.value);
      if (trimmedConfirmPasswordValue === '') {
          setConfirmPasswordError(true);

          //SOP-11 - modified By TS -(Testing feedback point -08 )
          //Field name:Confirm Password,Alert message Need Spaces between confirm and password 
          setConfirmPasswordErrorMessage("Confirm Password is required");
      } 
      else if( trimmedConfirmPasswordValue.length >12 ){
          setConfirmPasswordError(true);
          setConfirmPasswordErrorMessage("Max 12 characters");
      }
      else if( PasswordValue !== trimmedConfirmPasswordValue){
        setConfirmPasswordError(true);
        setConfirmPasswordErrorMessage("Passwords must match");
    }
      else {
          setConfirmPasswordError(false);
          setConfirmPasswordErrorMessage("");
      }
    };

    // Included by sanjana DATE:12-03-2024
    // const secretPass = "XkhZG4fW2t2W";

    const encryptData = async () => {
        try {
          const data = CryptoJS.AES.encrypt(JSON.stringify(ConfirmPasswordValue),secretPass).toString();
            //console.log("Encrypted Data:", data);
            //  setEncrptedData(data);
            return data
        } catch (error) {
          console.error("Error encrypting data:", error);
        }
      };
  
    
    // End of DATE:12-03-2024
    


    const createCompany = async (event) => {
        event.preventDefault();

            // Encrypt the data
            // await encryptData();
            // Commented by sanjana SOP-41 delcared in CreateCompanyAPI
            // const GetEncryptedData = await encryptData()
            // End of SOP-41
            //console.log("GetEncryptedData",GetEncryptedData)

        // Included by sanjana DATE:11-03-2024
    
            const companyEmailList = await GetCompanyExistEmail();
            
            if (companyEmailList.length > 0) {
                setEmailError(true);
                setEmailErrorMessage("Email is already registered");
                return;
            }

            const companyCompanyNameList = await GetCompanyExistCompanyName();
            if (companyCompanyNameList.length > 0) {
                setCompanyNameError(true);
                setCompanyNameErrorMessage("Company Name is already registered");
                return;
            }

            // End of DATE:11-03-2024

        let errors = {};
    
        const trimmedCompanyNameValue = CompanyNameValue.trim();
        if (trimmedCompanyNameValue === '') {
            errors.companyName = true
            setCompanyNameErrorMessage("Company name is required");
        }
        else if( trimmedCompanyNameValue.length >225 ){
            errors.companyName = true
            setCompanyNameErrorMessage("Max 255 characters");
        }
    
        const trimmedUserNameValue = UserNameValue.trim();
        if (trimmedUserNameValue === '') {
            errors.userName = true;
            setUserNameErrorMessage("Name of the person is required");
        }
        else if( trimmedUserNameValue.length >255 ){
            errors.userName = true;
            setUserNameErrorMessage("Max 255 characters");
        }
        else if( !/^[A-Za-z\s][A-Za-z.\s]*$/.test(trimmedUserNameValue) ){
            errors.userName = true;
            setUserNameErrorMessage("Alphabets only");
        }
    
        if (selectedCountryId === '' || selectedCountryId === null || selectedCountryId === undefined) {
            errors.country = true;
            setCountryErrorMessage("Country is required")
        }
    
        const trimmedEmailValue = EmailValue.trim();
        if (trimmedEmailValue === '') {
            errors.email = true;
            setEmailErrorMessage("Email is required");
        }
        else if( !(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(trimmedEmailValue) ){
            errors.email = true;
            setEmailErrorMessage("Invalid Email");
        }
        else if( trimmedEmailValue.length >255 ){
            errors.email = true;
            setEmailErrorMessage("Max 255 characters");
        }
    
        const trimmedMobileValue = mobileValue.trim();
        if (trimmedMobileValue === '') {
            errors.mobile = true;
            
            //SOP-11 - modified TS (Testing feedback points -17)
            //Field name:Mobile  while save without mobile then getting a alert message "Mobile number is required".To follow unformity field name to be rename  as "Mobile number"
            // setMobileErrorMessage("Mobile number is required");
            setMobileErrorMessage("Mobile is required");

        } 
        else if( trimmedMobileValue.length >15 ){
            errors.mobile = true;
            setMobileErrorMessage("Invalid mobile number");
        }
        else if( !/^[0-9]+$/.test(trimmedMobileValue) ){
            errors.mobile = true;
            setMobileErrorMessage("Numbers only");
        }

        const strongPasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;
    
        const trimmedPasswordValue = PasswordValue.trim();
        if (trimmedPasswordValue === '') {
            errors.password = true;
            setPasswordErrorMessage("Password is required");
        } 
        else if( trimmedPasswordValue.length >12 ){
            errors.password = true;
            setPasswordErrorMessage("Max 12 characters");
        } 
        else if (!strongPasswordRegex.test(trimmedPasswordValue)) {
            setPasswordError(true);
            errors.password = true;
            // Modified by sanjana SOP-41 to change Test@123 as Must contains atleast one character, number & special character
            // setPasswordErrorMessage("Enter a strong password (e.g., Test@123)");
            setPasswordErrorMessage("Enter a strong password (Must contains atleast one character, number & special character)");
            // End of SOP-41
        }      
        if (ConfirmPasswordValue !== '') {
            if (trimmedPasswordValue !== ConfirmPasswordValue) {
                errors.confirmPassword = true;
              setConfirmPasswordError(true);
              setConfirmPasswordErrorMessage("Passwords must match");
            } else {
              setConfirmPasswordError(false);
              setConfirmPasswordErrorMessage("");
            }
          }
    
        const trimmedConfirmPasswordValue = ConfirmPasswordValue.trim();
        if (trimmedConfirmPasswordValue === '') {
            errors.confirmPassword = true;
            setConfirmPasswordErrorMessage("Confirm Password is required");
        }
        else if( trimmedConfirmPasswordValue.length >12 ){
            errors.confirmPassword = true;
            setConfirmPasswordErrorMessage("Max 12 characters");
        }
        else if( PasswordValue !== trimmedConfirmPasswordValue){
          errors.confirmPassword = true;
          setConfirmPasswordErrorMessage("Passwords must match");
      }
    
        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setCompanyNameError(errors.companyName || false);
            setUserNameError(errors.userName || false);
            setCountryError(errors.country || false);
            setEmailError(errors.email || false);
            setMobileError(errors.mobile || false);
            setPasswordError(errors.password || false);
            setConfirmPasswordError(errors.confirmPassword || false);

            return;
        }

        // Included by sanjana SOP-41  to open dialouge box and to send otp for the respective mail id
        handleSignUpOTPOpen();

        const OTPPayload ={
            RegisterdEmail:EmailValue,
            OTP:OTP
        }

        //console.log("OTPPayload",OTPPayload)

        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/company/OTPMail`, OTPPayload)
        .then((res) => {
            if (res.status === 200) {
            }
        }).catch((error) => {
            console.error("Error:", error);
        });
        // End of SOP-41
    
        // Commented by sanjana SOP-41  declared in CreateCompanyAPI

        // To get Registered date
        // const ToGetDate = new Date(); 
        // const ToGetYear = ToGetDate.getFullYear();
        // const ToGetMonth = (ToGetDate.getMonth() + 1).toString().padStart(2, '0');
        // const ToGetDay = ToGetDate.getDate().toString().padStart(2, '0');
        // const FormatedRegisteredDate = `${ToGetYear}-${ToGetMonth}-${ToGetDay}`;
    
        // const [year, month, day] = FormatedRegisteredDate.split("-").map(Number);
    
        // const registeredDate = new Date(year, month - 1, day); // Month is zero-indexed in Date object
    
        // registeredDate.setDate(registeredDate.getDate() + 60);
        // // Get the year, month, and day after 30 days
        // const ValidTillYear = registeredDate.getFullYear();
        // const ValidTillMonth = (registeredDate.getMonth() + 1).toString().padStart(2, '0');
        // const ValidTillDay = registeredDate.getDate().toString().padStart(2, '0');
        // const ValidTillFormatted = `${ValidTillYear}-${ValidTillMonth}-${ValidTillDay}`;

    
        // const CompanyPayload = {
        //     SaveUpdateKey: 'create',
        //     CompanyId: 0,
        //     CompanyName: CompanyNameValue,
        //     UserName: UserNameValue,
        //     RegisterdEmail: EmailValue,
        //     RegistredMobile: mobileValue,
        //     CountryId: selectedCountryId,
        //     PlanId: 1,
        //     NumberOfAssets: 100,
        //     // Modified by sanjana DATE:12-03-2024
        //     // CompanyPassword: ConfirmPasswordValue,
        //     CompanyPassword: GetEncryptedData,
        //     // End of DATE:12-03-2024   
        //     RegistredDate: FormatedRegisteredDate,
        //     ValidTillDate: ValidTillFormatted,
        //     Active: 'Y',
        //     Currency: '',
        //     PaymentSchedule: ''
        // };
            
            // commonService
            //     .postService(`${process.env.REACT_APP_BACKEND_URL}/company/createCompany`, CompanyPayload)
            //     .then((res) => {
            //         if (res.status === 200) {
            //             //console.log("res", res);
            //             handleSingUpPageClose()

            //             // SOP-11 - TS (Testing Points -16)
            //             // Instead of display confirmation messsage "Registered successful" to be display as "Registered Successfully"
            //             // toast.success("Registered Successful");
            //             toast.success("Registered Successfully");
            //         }
            //     }).catch((error) => {
            //         console.error("Error:", error);
            //     });
            // End of SOP-41
    };


    // Included by sanjana SOP-41 to resend OTP for the respective mail
    
    const ResendOTP =()=>{
        const OTPPayload ={
            RegisterdEmail:EmailValue,
            OTP:OTP
        }

        //console.log("OTPPayload",OTPPayload)

        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/company/OTPMail`, OTPPayload)
        .then((res) => {
            if (res.status === 200) {
            }
        }).catch((error) => {
            console.error("Error:", error);
        });
    }
    // End of SOP-41

    // SOP-77 Included by PCS [to decrypt OTP before validating]
    // const secretPass = "XkhZG4fW2t2W"; 
    let decryptedDataOTP;
    if (GlobalOTPFromSession && typeof GlobalOTPFromSession === 'string' && GlobalOTPFromSession.trim() !== '')
    {
        const bytes = CryptoJS.AES.decrypt(GlobalOTPFromSession, secretPass);
        decryptedDataOTP = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }  
    //EOF SOP-77 Included by PCS [to decrypt OTP before validating]

  //SOP-79 Included By PCS  [to send new registration as email to sales team]
        const sendregistrationEmail =(CompanyPayload)=>
            {
            const RegistrationInformation = {
                    CompanyName: CompanyPayload.CompanyName,
                    PlanId: CompanyPayload.PlanId,
                    ValidTillDate:CompanyPayload.ValidTillDate,
                    salesTeamEmail:decryptedSalesEmailFromSession  
                    
                        };
                //  console.log('val',RegistrationInformation)
                commonService
                    .postService(`${process.env.REACT_APP_BACKEND_URL}/company/toSendRegistrationEmail`, RegistrationInformation)
                    .then((res) => {
                        
                        if (res.status === 200) {}
                                    })
                    .catch((error) => {
                            console.error("Error while sending reg email:", error);
                                    });

            }
   //EOF SOP-79 Included By PCS  [to send new registration as email to sales team]


    // Included by sanjana SOP-41 to create company after OTP
    //SOP-79 Modified By PCS [to validate OTP on enter button]
    //    const CreateCompanyAPI = async ()=>{
        const CreateCompanyAPI= async(event)=>{
    //EOF SOP-79 Modified By PCS [to validate OTP on enter button] 
          event.preventDefault();//SOP-79 Included  By PCS

        let APIValidation = true;

    const GetEncryptedData = await encryptData()
    //console.log("GetEncryptedData",GetEncryptedData)


    // To get Registered date
    const ToGetDate = new Date(); 
    const ToGetYear = ToGetDate.getFullYear();
    const ToGetMonth = (ToGetDate.getMonth() + 1).toString().padStart(2, '0');
    const ToGetDay = ToGetDate.getDate().toString().padStart(2, '0');
    const FormatedRegisteredDate = `${ToGetYear}-${ToGetMonth}-${ToGetDay}`;

    const [year, month, day] = FormatedRegisteredDate.split("-").map(Number);

    const registeredDate = new Date(year, month - 1, day); // Month is zero-indexed in Date object

    registeredDate.setDate(registeredDate.getDate() + 60);
    // Get the year, month, and day after 30 days
    const ValidTillYear = registeredDate.getFullYear();
    const ValidTillMonth = (registeredDate.getMonth() + 1).toString().padStart(2, '0');
    const ValidTillDay = registeredDate.getDate().toString().padStart(2, '0');
    const ValidTillFormatted = `${ValidTillYear}-${ValidTillMonth}-${ValidTillDay}`;

    const CompanyPayload = {
        SaveUpdateKey: 'create',
        CompanyId: 0,
        CompanyName: CompanyNameValue,
        UserName: UserNameValue,
        RegisterdEmail: EmailValue,
        RegistredMobile: mobileValue,
        CountryId: selectedCountryId,
        PlanId: 1,
        NumberOfAssets: 100,
        CompanyPassword: GetEncryptedData,
        // End of DATE:12-03-2024   
        RegistredDate: FormatedRegisteredDate,
        ValidTillDate: ValidTillFormatted,
        Active: 'Y',
        Currency: '',
        PaymentSchedule: ''
    };

        //console.log("GlobalOTPFromSession",GlobalOTPFromSession)
        //console.log("otp check",OTPValue)

    
        // SOP-77 Modified by PCS [validating decrypted value with OTP]  
        //if(GlobalOTPFromSession !== OTPValue){
            if(decryptedDataOTP !== OTPValue){
                toast.error("Invalid OTP")
                APIValidation= false  
            }
        //EOF SOP-77 Modified by PCS [validating decrypted value with OTP]     

      //console.log("CompanyPayload",CompanyPayload)
        if(APIValidation){
            commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/company/createCompany`, CompanyPayload)
                .then((res) => {
                    if (res.status === 200) {
                        //console.log("res", res);
                        handleSingUpPageClose();
                        handleSignUpOTPClose();
                        toast.success("Registered Successfully");
                        Reset()
                        setOTPValue('')
                        sendregistrationEmail(CompanyPayload);//SOP-79 Included By PCS [new reg as email to sales team ]
                        sessionStorage.clear('GlobalOTP');
                    }
                }).catch((error) => {
                    console.error("Error:", error);
                });
        }

    }
    // End of SOP-41

    //   Include by sanjana DATE:11-03-2024
    const GetCompanyExistEmail = async () => {   
        
        const  ExistEmailPayload = {
            ExistEmail:EmailValue
        }
        try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/company/getCompanyEmailExists`,ExistEmailPayload);
        //console.log(res.data.data);
        return res.data.data || [];
        } catch (err) {
        console.log( "co",err); 
        }
    };

    //   End of DATE:11-03-2024

    //   Include by sanjana DATE:11-03-2024
    const GetCompanyExistCompanyName = async () => {   
    
        const  ExistCompanyNamePayload = {
            ExistCompanyName:CompanyNameValue
        }
        try {
          const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/company/getCompanyNameExists`,ExistCompanyNamePayload);
          //console.log(res.data.data);
          return res.data.data || [];
        } catch (err) {
          console.log( "co",err); 
        }
      };
    
    //   End of DATE:11-03-2024


    // Included by sanjana SOP-41 to set timer
    const getTimeRemaining = (e) => {
        const total =
            Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor(
            (total / 1000 / 60) % 60
        );
        const hours = Math.floor(
            (total / 1000 / 60 / 60) % 24
        );
        return {
            total,
            hours,
            minutes,
            seconds,
        };
    };
    
    
    const startTimer = (e) => {
        let { total, hours, minutes, seconds } =
            getTimeRemaining(e);
        if (total >= 0) {
            // update the timer
            // check if less than 10 then we need to
            // add '0' at the beginning of the variable
            setTimer(
                (hours > 9 ? hours : "0" + hours) +
                ":" +
                (minutes > 9
                    ? minutes
                    : "0" + minutes) +
                ":" +
                (seconds > 9 ? seconds : "0" + seconds)
            );
        }
        else {
            // Time is up
            setTimer("00:00:00"); // Set timer to 00:00:00 or any message you want to display
            clearInterval(Ref.current); // Stop the timer
            sessionStorage.removeItem("GlobalOTP")//SOP-77 feedback points  Included By PCS [to remove otp from session after 5 mins]
            setResendDisabel(false)
            
        }
    };
    
    const FormatedTimer = (e) => {
        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next
        setTimer(`00:0${process.env.REACT_APP_SAMRTOPZ_OTP_TIMER}:00`);
    
        // If you try to remove this line the
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000);
        Ref.current = id;
    };
    
    const getDeadTime = () => {
        let deadline = new Date();
    
        // This is where you need to adjust if
        // you entend to add more time
        deadline.setMinutes(deadline.getMinutes() + parseInt(process.env.REACT_APP_SAMRTOPZ_OTP_TIMER, 10));
        return deadline;
    };
    
    const generateOTP =()=> { 
      
        // Declare a digits variable 
        // which stores all digits  
        let digits = '0123456789'; 
        let FormatedOTP = ''; 
        let len = digits.length 
        for (let i = 0; i < 4; i++) { 
            FormatedOTP += digits[Math.floor(Math.random() * len)]; 
        } 
    
        // console.log( FormatedOTP)
        OTP=FormatedOTP

       // SOP-77 Included by PCS [to encrypt OTP before storing in session]
       //sessionStorage.setItem("GlobalOTP",FormatedOTP) Commented By PCS
       const dataOTP = CryptoJS.AES.encrypt(JSON.stringify(FormatedOTP),secretPass).toString();
       //console.log("Encrypted OTP ata:", dataOTP);
          sessionStorage.setItem("GlobalOTP",dataOTP) 
       //EOF SOP-77 Included by PCS  [to encrypt OTP before storing in session

       
    }

    const Reset = () => {
        // Stop the timer
        clearInterval(Ref.current);
        // Reset the timer display
        setTimer('00:00:00');
    };

    // End of SOP-41
    


      useEffect(() => {
        GetCountryList()
        // Included by sanjana DATE:11-03-2024
        // GetCompanyExistEmail()
        // End of DATE:11-03-2024

        // Included by sanjana SOP-41 to render timer in useffect
        FormatedTimer(getDeadTime());
        // End of SOP-41
        getIntopzSalesEmail();//SOP-79 Included By PCS[to get sales email on page load]
        
    }, []);

    return(
        <Box>
                <Box>
        <Box  height={70} bgcolor={'#4169e1'}>
            <Box  display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Typography fontSize={'1.5rem'} color={'white'}>
                    SIGN UP
                </Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Typography fontSize={'1.3rem'} color={'white'}>
                    Selected plan: Free
                </Typography>
            </Box>
        </Box>


        <Box p={3}>
            <form noValidate>
            <Grid mt={3} container>
                <Grid item lg={12} md={12} xs={12}>
                    <TextField
                        type='text'
                        variant="standard"
                        label='Company Name'
                        name='CompanyName'
                        style={{ margin: '0 10px' }}
                        fullWidth
                        required
                        error={CompanyNameError}
                        onChange={handleCompanyNameChange}
                        value={CompanyNameValue}
                        helperText={CompanyNameErrorMessage}
                        
                        //SOP-11 - TS (Testing Point -02) - Start    
                        //Initial focus to be set on first control   
                        autoFocus  // Add autoFocus prop here
                        //SOP-11 - TS (Testing Point -02) - end    
                    /> 
                </Grid>
            </Grid>

            <Grid mt={3} container display={'flex'} justifyContent={'space-between'}>
                <Grid item lg={5}>
                        <TextField
                            type='text'
                            variant="standard"
                            label='Name of the person'
                            name='UserName'
                            style={{ margin: '0 10px' }}
                            fullWidth
                            required
                            onChange={handleUserNameChange}
                            value={UserNameValue}
                            error={UserNameError}
                            helperText={UserNameErrorMessage}
                        />
                    </Grid>
                    <Grid  item lg={5}>
                    <Autocomplete
                        id="combo-box-demo"
                        options={countryOptions}
                        filterOptions={filterOptions}
                        getOptionLabel={(option) => option.name}
                        value={countryOptions.find(option => option.value === selectedCountryId) || null}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                setSelectedCountryId(newValue.value);
                                setCountryError(false);
                                setCountryErrorMessage("");
                            } else {
                                setSelectedCountryId(null);
                                setCountryError(true);
                                setCountryErrorMessage("Country is required");
                            }
                        }}
                        sx={{ width: 235, ml: 1 }}
                        renderInput={(params) => 
                        <TextField {...params}
                        error={CountryError}
                        helperText={CountryErrorMessage}
                            required label="Country" 
                            variant="standard" />}
                    />
                    </Grid>
            </Grid>

            <Grid mt={3} container display={'flex'} justifyContent={'space-between'}>
                <Grid item lg={5}>
                    <TextField
                        type='text'
                        variant="standard"
                        label='Email'
                        name='Email'
                        style={{ margin: '0 10px' }}
                        fullWidth
                        required
                        value={EmailValue}
                        error={EmailError}
                        helperText={EmailErrorMessage}
                        onChange={handleEmailChange}
                    /> 
                </Grid>
                <Grid item lg={5}>
                    <TextField
                    type='text'
                                    
                    //SOP-11 - TS (Testing Point -04) - Start    
                    //It should not allow to enter more than 15 digits in mobile number fields                 
                    inputProps={{
                        maxLength: 15
                    }}
                    //SOP-11 - TS (Testing Point -04) - end  
                                    
                    variant="standard"
                    label='Mobile'
                    name='Mobile'
                    style={{ margin: '0 10px' }}
                    fullWidth
                    required
                    value={mobileValue}
                    error={mobileError}
                    helperText={mobileErrorMessage}
                    onChange={handleMobileChange}

                />
                </Grid>
            </Grid>

            <Grid mt={3} container display={'flex'} justifyContent={'space-between'}>
                <Grid item lg={5}>
                    <TextField
                                    
                    //SOP-11 - TS (Testing Point -01)                
                    // type='password'
                    type={showPassword ? 'text' : 'password'}
                                    
                    //SOP-11 - TS (Testing Point -04) - Start 
                    //It should not allow to enter more than 12 characters password&Confirm password  field                
                    inputProps={{
                        maxLength: 12
                    }}
                    //SOP-11 - TS (Testing Point -04) - end 
                                    
                    variant="standard"
                    label='Password'
                    name='Password'
                    style={{ margin: '0 10px' }}
                    fullWidth
                    required
                    value={PasswordValue}
                    error={PasswordError}
                    helperText={PasswordErrorMessage}
                    onChange={handlePasswordChange}
                                    
                    //SOP-11 - TS (Testing Point -01) - start
                    //Need Eye visible on the right side of the password and confirm password text box                  
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleTogglePasswordVisibility}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                        }}
                        //SOP-11 - modified by TS (Testing Point -01) - end 
                /> 

                </Grid>
                <Grid item lg={5}>
                    <TextField
                     
                    //SOP-11 - modified by TS (Testing Point -01) - start
                    //Need Eye visible on the right side of the password and confirm password text box                
                    // type='password'
                    type={showPasswordConfirm ? 'text' : 'password'}
                    //SOP-11 - modified by TS (Testing Point -01) - end
                    
                    //SOP-11 - TS (Testing Point -04) - Start 
                    //It should not allow to enter more than 12 characters password&Confirm password  field                
                    inputProps={{
                        maxLength: 12
                    }}
                    //SOP-11 - TS (Testing Point -04) - end  
                                    
                    variant="standard"
                    label='Confirm Password'
                    name='ConfirmPassword'
                    style={{ margin: '0 10px' }}
                    fullWidth
                    required
                    value={ConfirmPasswordValue}
                    error={ConfirmPasswordError}
                    helperText={ConfirmPasswordErrorMessage}
                    onChange={handleConfirmPasswordChange}
                    
                    //SOP-11 - TS (Testing Point -01) - start
                    //Need Eye visible on the right side of the password and confirm password text box                     
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleTogglePasswordVisibilityConfirm}
                                    edge="end"
                                >
                                    {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    //SOP-11 - modified by TS (Testing Point -01) - end             
                /> 
                    
                </Grid>
            </Grid>

            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>

            <Button
                style={{
                    background: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    padding: "0 70px",
                    // color: {accent},
                    height: "3rem",
                    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                }}
                // onClick={()=>{
                //     handleSignUpClose()
                //     // window.location.reload()
                //     // handleValueAndErrorClear()
                // }}

                onClick={handleSingUpPageClose} 
            >
                Back
            </Button>

            <Button
                style={{
                    background: '#4169e1',
                    border: 'none',
                    borderRadius: '5px',
                    padding: "0 70px",
                    color: 'white',
                    height: "3rem",
                    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                }}
                // type='submit'
                onClick={(event)=>{
                    createCompany(event);
                    FormatedTimer(getDeadTime());
                    generateOTP();
                }}
            >
                Submit
            </Button>
            </Box>

            </form>
        </Box>
        </Box>
        
        {/* Included by sanajana SOP-41 to get signup page */}
        <Box >
        <Dialog  open={signUpOTPOpen} fullWidth>
        {/* //SOP-79 Modified By PCS [to validate OTP on enter button- enclosed OTP in form tag] */}
        <form>
        <Box bgcolor={'white'} 
                sx={{
                    width:{
                        lg:600,
                        md:600,
                        xs:388
                    }
                }}
                display={ 'flex'} justifyContent={'center'}>
                    <Box mb={5}>
                        <Box sx={{
                    width:{
                        lg:600,
                        md:600,
                        xs:388
                    }
                }} bgcolor={'#4169e1'} display={ 'flex'} justifyContent={'center'}>
                            <Typography  variant='h4' color={'white'} mt={2} mb={1} >
                                OTP Verification
                            </Typography>
                        </Box>
                        <Box mt={5}>
                            <Typography fontSize={'1.3rem'} display={ 'flex'} justifyContent={'center'}>
                                OTP has been sent to 
                            </Typography>
                            <Typography fontSize={'1.3rem'} display={ 'flex'} justifyContent={'center'}>
                                {EmailValue} ( Valid for 5 minutes )
                            </Typography>
                        </Box>
                        <Box mt={5} display={'flex'} justifyContent={'center'}>
                            <OtpInput
                            value={OTPValue}
                            onChange={setOTPValue}
                            numInputs={4}
                            renderSeparator={<span style={{marginLeft:25}}>   </span>}
                            inputStyle={{ 
                                width: '40px', 
                                height: '60px', 
                                fontSize: '20px',
                                borderRadius:10,
                                border:'2px solid #4169e1'
                            }}
                            renderInput={(props) => <input {...props} />}
                            />
                        </Box>

                        <Box mt={5} display={ 'flex'} justifyContent={'center'}>
                            <Typography mt={1} fontSize={'1.3rem'} display={ 'flex'} justifyContent={'center'}>
                                Don't receive the OTP? 
                            </Typography>
                            <Button ml={1} fontSize={'1.3rem'} display={ 'flex'} justifyContent={'center'}
                            onClick={()=>{
                                generateOTP()
                                setResendDisabel(true)
                                ResendOTP();
                                FormatedTimer(getDeadTime());
                            }} 
                            disabled={ResendDisabel}
                            sx={{
                                cursor:'pointer',
                                color:'#4169e1',
                                fontSize:'1.3rem',
                                ":hover":{
                                    bgcolor:'white'
                                }
                            }}
                            >
                                RESEND
                            </Button>
                        </Box>

                        <Box mt={5} display={ 'flex'} justifyContent={'center'}>
                            <Typography fontSize={'1.3rem'} display={ 'flex'} justifyContent={'center'}>
                                {timer}
                            </Typography>
                        </Box>    
                        
                        <Box mt={5} display={ 'flex'} justifyContent={'center'} >
                        <Button
                            style={{
                                background: '#4169e1',
                                border: 'none',
                                borderRadius: '5px',
                                padding: "0 70px",
                                color: 'white',
                                height: "3rem",
                                boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                            }}
                          //SOP-79 Modified By PCS [to validate OTP on enter button] 
                            //type='submit'
                            type='submit'
                          //EOF SOP-79 Modified By PCS [to validate OTP on enter button] 
                            onClick={(event)=>{
                                 //SOP-79 Modified By PCS
                                // CreateCompanyAPI();
                                CreateCompanyAPI(event);
                            }}
                        >
                            Submit
                        </Button>
                        </Box>

                    </Box>

            </Box>
        </form>
        {/* //EOF SOP-79 Modified By PCS [to validate OTP on enter button- enclosed OTP in form tag] */}
        </Dialog>

        </Box>
        {/* End of SOP-41 */}

        </Box>
    )
}

export default HomeSignUpPage;