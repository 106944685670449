import { useState} from 'react'; // Import useState hook
import { useEffect} from 'react'; // SOP-53 Included By PCS
import React from 'react';
import {
    Box,
    TextField,
    useMediaQuery,
    Grid,
    Button,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import SmartphoneIcon from '@mui/icons-material/Smartphone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import Typography from '@mui/material/Typography';
import CustomerSupportImg from '../Assests/CustomerSupport.png';

// SOP-53 Included By PCS
import { commonService } from '../Helpers/commonService.helper';
import { toast } from 'react-toastify';
// EOF SOP-53 Included By PCS

const ContactUsPage = () => {
    const isLargeScreen = useMediaQuery('(min-width:600px)'); // Check if the screen size is larger than 600px


    const relatedOptions =[
        { label: 'Sales', value:'Sales' },
        { label: 'Support', value:'Support'},
                          ]

const [mobileError, setMobileError]=useState(false)
const [mobileValue, setMobileValue]=useState('')
const [mobileErrorMessage, setMobileErrorMessage]=useState(false)


//SOP-53 Included By PCS

//TO get current date and time ,format it and send it to payload 

const ToGetDate = new Date(); 
const ToGetYear = ToGetDate.getFullYear();
const ToGetMonth = (ToGetDate.getMonth() + 1).toString().padStart(2, '0');
const ToGetDay = ToGetDate.getDate().toString().padStart(2, '0');
const FormatedEnquiredDate = `${ToGetYear}-${ToGetMonth}-${ToGetDay}`;

//change date format
const parts = FormatedEnquiredDate.split('-');
const FormattedDate = parts.reverse().join('/');

const FormattedTime = ToGetDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });

const formattedDateTime = `${FormattedDate}  ${FormattedTime}`;
//console.log('formattedDateTime',formattedDateTime)


//Initializing variable 
const [nameError, setNameError]=useState(false)
const [nameValue, setNameValue]=useState('')
const [nameErrorMessage, setNameErrorMessage]=useState(false)

const [emailError, setEmailError]=useState(false)
const [emailValue, setEmailValue]=useState('')
const [emailErrorMessage, setEmailErrorMessage]=useState(false)

const [enquiryRelatedToError, setEnquiryRelatedToError]=useState(false)
const [enquiryRelatedToValue, setEnquiryRelatedToValue] = useState(null);
const [enquiryRelatedToErrorMessage, setEnquiryRelatedToErrorMessage]=useState(false)

const [enquiryTextError, setEnquiryTextError]=useState(false)
const [enquiryTextValue, setEnquiryTextValue]=useState('')
const [enquiryTextErrorMessage, setEnquiryTextErrorMessage]=useState(false)

const [adminEmail, setAdminEmail]=useState('')// Initializing admin email



//Handle submit starts
 const handleSubmit = (event) => {
               
                event.preventDefault();
                let errors = {};
                                                     
    //Validating Name 
        const trimmedNameValue = nameValue.trim();
                if (trimmedNameValue === '') {
                    errors.name = true;
                    setNameErrorMessage("Name of the person is required");
                }
                else if( trimmedNameValue.length >255 ){
                    errors.name = true;
                    setNameErrorMessage("Max 255 characters");
                    } 
                else if( !/^[A-Za-z\s][A-Za-z.\s]*$/.test(trimmedNameValue) ){
                    errors.name = true;
                    setNameErrorMessage("Alphabets only");
                    }
                 

    //Validating Email
        const trimmedEmailValue = emailValue.trim();
                if (trimmedEmailValue === '') {
                    errors.email = true;
                    setEmailErrorMessage("Email is required");
                }
                else if( trimmedEmailValue.length >255 ){
                    errors.email = true;
                    setEmailErrorMessage("Max 255 characters");
                }
               else if( !(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(trimmedEmailValue) ){
                    errors.email = true;
                    setEmailErrorMessage("Enter valid email");
                }

    //Validating Mobile number       
        const trimmedMobileValue = mobileValue.trim();
                if (trimmedMobileValue === '') {
                    errors.mobile = true;
                    setMobileErrorMessage("Mobile number is required");
                                                } 
                else if( trimmedMobileValue.length <10 ){
                    errors.mobile = true;
                    setMobileErrorMessage("Invalid mobile number");
                                                        }
                else if( !/^[+0-9]+$/.test(trimmedMobileValue) ){
                    errors.mobile = true;
                    setMobileErrorMessage("Numbers only");
                                                              }  

    //Validating enquiry 'Related to'
        const trimmedEnquiryRelatedtoValue = enquiryRelatedToValue;
                     if (trimmedEnquiryRelatedtoValue === '' || trimmedEnquiryRelatedtoValue === null || trimmedEnquiryRelatedtoValue === undefined) {
                        errors.enquiryrelatedto = true;
                        setEnquiryRelatedToErrorMessage("Related to is required")
                     }
                    

    //Validating Enquiry text
        const trimmedEnquiryTextValue = enquiryTextValue.trim();
                    if (trimmedEnquiryTextValue === '') {
                        errors.enquirytext = true;
                        setEnquiryTextErrorMessage("Enquiry is required");
                                                         }
                    else if( trimmedEnquiryTextValue.length >255 ){
                        errors.enquirytext = true;
                        setEnquiryTextErrorMessage("Max 255 characters");
                                                                  }
  
     if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
               setMobileError(errors.mobile || false);
               setNameError(errors.name || false);
               setEmailError(errors.email || false);
               setEnquiryRelatedToError(errors.enquiryrelatedto || false);
               setEnquiryTextError(errors.enquirytext || false);
                    return;                
                                         }

    
//Sending enquiry as  email
        const EnquiryInformation = {
            
            IntopzAdminEmail:adminEmail,
            SenderName: nameValue,
            SenderEmail: emailValue,
            SenderMobile: mobileValue,
            RelatedTo: enquiryRelatedToValue,
            EnquiryText: enquiryTextValue,
            EnquiredDate:formattedDateTime,
          

                              };

      //console.log('EnquiryInformation',EnquiryInformation)
        commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/intopzadmin/sendenquiry_intopzadmin`, EnquiryInformation)
            .then((res) => {
                // console.log('res is',res)
                 if (res.status === 200) {
                    toast.success("Email sent successfully");
                    //SOP-53 Included By PCS [To clear the fields after submitting]
                        setNameValue('')
                        setEmailValue('')
                        setMobileValue('')
                        setEnquiryRelatedToValue('')
                        setEnquiryTextValue('')
                    //EOF SOP-53 Included By PCS [To clear the fields after submitting]
                                        }
                            })
            .catch((error) => {
                      console.error("Error:", error);
                              });
  
//EOF SOP-53 Included By PCS

                                  };
//Handle submit ends here

//Validating fields on change
// --Name--
const handleNameChange = (event) =>
    {
        const trimmedNameValue = event.target.value.trim();
        setNameValue(event.target.value);
                  if (trimmedNameValue === '') {
                      setNameError(true);
                      setNameErrorMessage("Name of the person is required");
                  } 
                  else if( trimmedNameValue.length >255 ){
                    setNameError(true);
                    setNameErrorMessage("Max 255 characters");
                         }
                  else if( !/^[A-Za-z\s][A-Za-z.\s]*$/.test(trimmedNameValue) ){
                    setNameError(true);
                    setNameErrorMessage("Alphabets only");
                     }
                 else {
                    setNameError(false);
                    setNameErrorMessage("");
                       }
    };
// --Email--
 const handleEmailChange = (event) =>
        {
            const trimmedEmailValue = event.target.value.trim();
            setEmailValue(event.target.value);
            if (trimmedEmailValue === '') 
                {
                setEmailError(true);
                setEmailErrorMessage("Email is required");
                } 
            else if( trimmedEmailValue.length >255 ){
                setEmailError(true);
                setEmailErrorMessage("Max 255 characters");
                                                    }
           
            else if( !(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(trimmedEmailValue) ){
                setEmailError(true);
                setEmailErrorMessage("Enter valid email");
                }
            else {
                setEmailError(false);
                setEmailErrorMessage("");
                 }
        };
       
// --Related to--
    const handleRelatedChange = (event, newValue) => 
        {
            if (newValue) {
                setEnquiryRelatedToValue(newValue.value);
                setEnquiryRelatedToError(false);
                setEnquiryRelatedToErrorMessage("");
                          } 
            else {
                setEnquiryRelatedToValue(null);
                setEnquiryRelatedToError(true);
                setEnquiryRelatedToErrorMessage("Related to is required");
                }
        };
// --Enquiry text-
 const handleEnquiryTextChange = (event) =>
    {
       const trimmedEnquiryTextValue = event.target.value.trim();
       setEnquiryTextValue(event.target.value);
                  if (trimmedEnquiryTextValue === '') {
                    setEnquiryTextError(true);
                    setEnquiryTextErrorMessage("Enquiry is required");
                                                    } 
                  else {
                    setEnquiryTextError(false);
                    setEnquiryTextErrorMessage("");
                       }
    };

//EOF SOP-53 

// --Mobile Number -
const handleMobileChange = (event) => 
    {
        const trimmedMobileValue = event.target.value.trim();
        setMobileValue(event.target.value);
        if (trimmedMobileValue === '') {
            setMobileError(true);
            setMobileErrorMessage("Mobile number is required");
        } 
        else if( trimmedMobileValue.length >10 ){
            setMobileError(true);
            setMobileErrorMessage("Invalid mobile number");
        }
        else if( !/^[+0-9]+$/.test(trimmedMobileValue) ){
             setMobileError(true);
             setMobileErrorMessage("Numbers only");
        }
        else {
            setMobileError(false);
            setMobileErrorMessage("");
              }
    };

    const handleLinkedinIconClick = () => {
        const googleLink = 'https://www.linkedin.com/company/smartopz/';
        window.open(googleLink, '_blank');
      };

      const handleYouTubeIconClick = () => {
        const googleLink = 'https://www.youtube.com/c/smartopz';
        window.open(googleLink, '_blank');
      };

      const handleFaceBookIconClick = () => {
        const googleLink = 'https://www.facebook.com/smartopz';
        window.open(googleLink, '_blank');
      };


 // SOP-53 Included By PCS [to get Intopz admin email]

 const getIntopzAdminEmail = () => 
    {
        commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/intopzadmin/get_intopz_admin_email`)
            .then((res) => {
            //console.log('res is',res.data.data[0].admin_email)
                if (res.status === 200) {
                    setAdminEmail(res.data.data[0].admin_email)
                                        }
                            })
            .catch((error) => {
                    console.error("Error:", error);
                              });
    }

//To get admin email on page load
        useEffect(() => {
                    getIntopzAdminEmail()
                    }, []);

  


// EOF SOP-53 Included By PCS [to get Intopz admin email]  

    return (
        <Box sx={{
            height: {
                xs: '145vh',
                lg: '97.5vh',
                xl:'85vh'
            },
            bgcolor: 'white',
            marginTop:'10%'
        }}>

            <Box>
                <Grid container justifyContent={'center'} display={'flex'} sx={{
                    padding:{
                        xs:3
                    }
                }} >
                    {/* // Changes done by TS */}
                    {/* <Grid lg={6} md={12} xs={4} mt={15}  item  xl={4}  */}
                    <Grid lg={3.5} md={8} sm={12} xs={12} item mt={15} xl={5}>
                        <Typography variant='h3' fontFamily={'Segoe UI'}>
                            Contact us
                        </Typography>
                        <Box mt={5} >
                            <form 
                            //SOP-53 Included By PCS
                              onSubmit={handleSubmit}   
                            //EOF SOP-53 Included By PCS
                            >
                                <Box display="flex">
                                    <TextField
                                        type='text'
                                        variant="standard"
                                        label='Name'
                                        name='Name'
                                        style={{ margin: '0 10px' }}

                                        //SOP-53 Included By PCS
                                        required
                                        error={nameError}
                                        helperText={nameErrorMessage}
                                        onChange={handleNameChange}
                                        value={nameValue}
                                        //EOF SOP-53
                                    />
                                    <TextField
                                        type='text'
                                        variant="standard"
                                        label='Email Address'
                                        name='Email'
                                        style={{ margin: '0 10px' }}
                                        //SOP-53 Included By PCS
                                        required
                                        error={emailError}
                                        helperText={emailErrorMessage}
                                        onChange={handleEmailChange}
                                        value={emailValue}
                                        //EOF SOP-53
                                    />
                                </Box>

                                <Box display="flex" mt={5}>
                                    <TextField
                                        error={mobileError}
                                        value={mobileValue}
                                        type='text'
                                        helperText={mobileErrorMessage}
                                        required
                                        variant="standard"
                                        label='Mobile number'
                                        onChange={handleMobileChange}
                                        name='Mobile'
                                        style={{ margin: '0 10px' }}
                                    />


                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={relatedOptions}
                                        sx={{ width: 190 }}
                                        //SOP-53 commented By PCS
                                            // disablePortal
                                            //renderInput={(params) => <TextField {...params} label= "Related to" variant="standard" />}
                                        //SOP-53 Inlcuded By PCS
                                        value={relatedOptions.find(option => option.value === enquiryRelatedToValue) || null}
                                        onChange={handleRelatedChange}
                                        renderInput={(params) => 
                                         <TextField {...params}
                                                error={enquiryRelatedToError}
                                                helperText={enquiryRelatedToErrorMessage}
                                                required label="Related to" 
                                                variant="standard" />}
                                        //EOF SOP-53 Inlcuded By PCS        
                                     /> 
                                </Box>

                                <Box display="flex" mt={5}>
                                    <TextField
                                        multiline
                                        type='text'
                                        variant="standard"
                                        label='Enquiry'
                                        name='Enquiry'
                                        style={{ margin: '0 10px', width: 380 }}
                                        // color={'P-dark'}
                                        //SOP-53 Included By PCS
                                         required
                                         error={enquiryTextError}
                                         helperText={enquiryTextErrorMessage}
                                         onChange={handleEnquiryTextChange}
                                         value={enquiryTextValue}
                                        //EOF SOP-53

                                    />
                                </Box>
                                {/* //SOP-53 Inlcuded By PCS [to restrict form submission only on enter button] */}
                                </form>
                                 {/* //EOF SOP-53 Inlcuded By PCS [to restrict form submission only on enter button] */}
                                <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <Button
                                        style={{
                                            background: 'white',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding: "0 70px",
                                            // color: {accent},
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                        }}
                                        type='submit'
                                        //SOP-53 Commented By PCS
                                            onClick={(event) => {
                                                handleSubmit(event);
                                            }}
                                        //EOF SOP-53 Commented By PCS
                                    >
                                        Submit
                                    </Button>
                                </Box>
                                {/* //SOP-53 Commented By PCS [to restrict form submission only on enter button] */}
                                   {/* </form> */}
                                {/* //EOF SOP-53 Commented By PCS [to restrict form submission only on enter button] */}
                            
                        </Box>
                    </Grid>

                    {/* // Changes done by TS */}
                    {/* <Grid lg={6} md={12} xs={4} mt={15}  item  xl={4}  */}
                    <Grid lg={6} md={8} xs={12} mt={15} sm={12} item  xl={4} 
                    sx={{
                        display:{
                            lg:'flex',
                            xs:'flex',
                            md:'flex',
                            xsl:'flex',
                        },
                        justifyContent:{
                            lg:'flex-end',
                            xs:'flex-start',
                            md:'flex-end',
                            xl:'flex-end',
                        }
                    }}
                    display={'flex'} justifyContent={'flex-end'}>
                        <Box>
                            <Typography variant='h3' fontFamily={'Segoe UI'}>
                                Contact info
                            </Typography>
                            <Box mt={2} display={'flex'}
                                sx={{
                                    '&:hover': {
                                        color: '#4169e1',
                                        cursor:'pointer'
                                    },
                                }}>
                                <SmartphoneIcon />
                                <Typography ml={3} fontFamily={'Segoe UI'}>
                                    9900694666
                                </Typography>
                            </Box>

                            <Box mt={2} display={'flex'}
                                sx={{
                                    '&:hover': {
                                        color: '#4169e1',
                                        cursor:'pointer'
                                    },
                                }}>
                                <WhatsAppIcon />
                                <Typography ml={3} fontFamily={'Segoe UI'}>
                                    9900694666
                                </Typography>
                            </Box>
                            <Box mt={2} display={'flex'}
                                sx={{
                                    '&:hover': {
                                        color: '#4169e1',
                                        cursor:'pointer'
                                    },
                                }}>
                                <MailOutlineIcon  />
                                <Typography ml={3} fontFamily={'Segoe UI'}>
                                    support@smartopz.com
                                </Typography>
                            </Box>
                            <Box mt={2} display={'flex'} onClick={handleLinkedinIconClick}
                                sx={{
                                    '&:hover': {
                                        color: '#4169e1',
                                        cursor:'pointer'
                                    },
                                }}>
                                <LinkedInIcon />
                                <Typography ml={3} fontFamily={'Segoe UI'}>
                                LinkedIn
                                </Typography>
                            </Box>

                            <Box mt={2} display={'flex'} onClick={handleYouTubeIconClick}
                                sx={{
                                    '&:hover': {
                                        color: '#4169e1',
                                        cursor:'pointer'
                                    },
                                }}>
                                <YouTubeIcon />
                                <Typography ml={3} fontFamily={'Segoe UI'}>
                                YouTube
                                </Typography>
                            </Box>
                            <Box mt={2} display={'flex'} onClick={handleFaceBookIconClick}
                                sx={{
                                    '&:hover': {
                                        color: '#4169e1',
                                        cursor:'pointer'
                                    },
                                }}>
                                <FacebookIcon  />
                                <Typography ml={3} fontFamily={'Segoe UI'}>
                                Facebook
                                </Typography>
                            </Box>
                        </Box>
                        {isLargeScreen && ( <img
                            src={CustomerSupportImg}
                            alt='CustomerSupport'
                            height={300}
                            width={300}
                            style={{
                                maxWidth: '100%',
                                height: 'auto',
                                transition: 'margin-top 1s ease-in',
                            }}
                        /> )}
                    </Grid>
                    

                </Grid>
            </Box>
        </Box>
    )
}

export default ContactUsPage;
