import { useState, useRef, useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
} from '@mui/material';
import { styled as muiStyled, alpha, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import AssetManagementImg from '../Assests/asset.png';
import TicketingImg from '../Assests/Ticketing.png';
import HRManagementImg from '../Assests/HRManagement.png';

import doc1 from '../Assests/doc1.jpg';

// SOP-68 - Web Page - Features - Content Changes) - TS

const ProductsPage = () => {
    return (
        <Box
            sx={{
                height: {
                    xs: '235vh',
                    lg: '100vh',
                    xl: '80vh',
                    md: '120vh'
                },
                bgcolor: '#f8f8ff',
                display: 'flex',
                flexDirection: 'column', // Set flex-direction to column
                justifyContent: 'center', // Center content vertically
            }}
        >
            <Box > 
               <Box display={'flex'} 
                alignItems={'center'} 
                justifyContent={'center'}
                flexDirection={'column'} 
                >
                    <Box display={'flex'} mt={17} justifyContent={'center'}>
                    <Typography variant='h3'fontFamily={'Segoe UI'}>
                        Features
                    </Typography>
                </Box>
                <Box   display={'flex'} alignItems={'center'} justifyContent={'center'}
                flexDirection={'column'}
                >
                    <Grid
                        container
                        display={'flex'}
                        alignItems={'stretch'}
                        justifyContent={'center'}
                        mt={3}
                        spacing={3}
                    >
                        {/* Asset Management */}
                        <Grid
                            item
                                // lg={4}
                                lg={2.9}
                            xs={12}
                            sm={6}
                            md={4}
                            display={'flex'}
                            justifyContent={'center'}
                        >
                                                        <Card sx={{ maxWidth:{ 
                                lg:350,
                                xl:430,
                                xs:400
                            }, height: '100%' }}>
                                <CardActionArea sx={{ minHeight: 400}}>
                                    <Box display={'flex'} justifyContent={'center'}>
                                        <img
                                            component="img"
                                                
                                            // Changes done by TS
                                            // height="150"
                                            // width={'auto'}
                                                
                                            height = 'auto'
                                            width = '85%'
                                            src={AssetManagementImg}
                                            alt="Asset management"
                                            style={{
                                                padding: 3
                                            }}
                                        />
                                    </Box>
                                    <CardContent sx={{ flexGrow: 1, minHeight: 100 }}> {/* Added minimum height and flex-grow */}
                                        <Typography display={'flex'} justifyContent={'center'} fontWeight={'600'}
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                            fontFamily={'Segoe UI'} 
                                            color={'#4169e1'}
                                        >
                                            Asset Management
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            fontSize={'1rem'}
                                            color="black"
                                            fontFamily={'Segoe UI'}
                                            >
                                                
                                        {/* (SOP-68) Web Page - Features - Content Changes - TS ( commented and included) - start */}
                                            {/* Our Smartopz can track all your inventory and is highly
                                            configurable to the way you currently manage your IT hardware
                                            and prevent inventory missing or misuse of license usage. */}
                                                
                                                Our Smartopz can track all your inventory and is highly configurable to the way you currently manage your IT hardware.
                                                Better asset tracking lead to improved operational cost which leads to better business performance.
                                        {/* (SOP-68) Web Page - Features - Content Changes - TS ( commented and included) - end */}
                                       
                                            </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>

                        </Grid>

                        {/* Ticketing System */}
                        <Grid
                            item
                                // lg={4}
                                lg={2.9}
                            xs={12}
                            sm={6}
                            md={4}
                            display={'flex'}
                            justifyContent={'center'}
                        >
                            <Card sx={{ maxWidth:{ 
                                lg:350,
                                xl:430,
                                xs:400
                            }, height: '100%' }}>
                                <CardActionArea sx={{ minHeight: 400}}>
                                    <Box display={'flex'} justifyContent={'center'}>
                                        <img
                                            component="img"
                                            
                                            // Changes done by TS
                                            // height="150"
                                            // width={'auto'}
                                                
                                            height = 'auto'
                                            width = '85%'
                                            src={TicketingImg}
                                            alt="Ticketing System"
                                            style={{
                                                padding: 3
                                            }}
                                        />
                                    </Box>
                                    <CardContent sx={{ flexGrow: 1, minHeight: 100 }}> {/* Added minimum height and flex-grow */}
                                        <Typography display={'flex'} justifyContent={'center'} fontWeight={'600'}
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                            fontFamily={'Segoe UI'} 
                                            color={'#4169e1'}
                                        >
                                            IT Ticketing System
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            fontSize={'1rem'}
                                            color="black"
                                            fontFamily={'Segoe UI'}
                                            >

                                        {/* (SOP-68) Web Page - Features - Content Changes - TS ( commented and included) - start */}
                                                
                                            {/* Better asset tracking leads to better business performance and
                                            operational cost. Our ticketing process helps to resolve
                                            day-to-day IT problem tickets faster. Track and analyze every
                                            ticket with SLA and time to resolve metrics. */}
                                                
                                                Efficient operations can only be achieved by proper handling of problem management. Our ticketing system helps to resolve day-to-day IT problem faster.
                                                Track and analyze every ticket with SLA and time to resolve metrics etc..
                                        {/* (SOP-68) Web Page - Features - Content Changes - TS ( commented and included) - end */}
                                                
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>

                        {/* HR Management */}
                        <Grid
                            item
                                // lg={4}
                                lg={2.9}
                            xs={12}
                            sm={6}
                            md={4}
                            display={'flex'}
                            justifyContent={'center'}
                        >
                            <Card sx={{ maxWidth:{ 
                                lg:350,
                                xl:430,
                                xs:400
                            }, height: '100%' }}>
                                <CardActionArea sx={{ minHeight: 400}}>
                                    <Box display={'flex'} justifyContent={'center'}>
                                        <img
                                            component="img"
                                                
                                            // Changes done by TS
                                            // height="150"
                                            // width={'auto'}
                                                
                                            height = 'auto'
                                            width = '56%'
                                            src={HRManagementImg}
                                            alt="HR Management"
                                            style={{
                                                padding: 3
                                            }}
                                        />
                                    </Box>
                                    <CardContent sx={{ flexGrow: 1, minHeight: 100 }}> {/* Added minimum height and flex-grow */}
                                        <Typography display={'flex'} justifyContent={'center'} fontWeight={'600'}
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                            fontFamily={'Segoe UI'}
                                            color={'#4169e1'}
                                        >
                                            HR Management
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            fontSize={'1rem'}
                                            color="black"
                                            fontFamily={'Segoe UI'}
                                            >
                                                
                                        {/* (SOP-68) Web Page - Features - Content Changes - TS ( commented and included) - start */}
                                            {/* Helps to track and analyze different employee information such
                                            as organization structure, employee induction/relieving process
                                            flow, time sheet, leave management, recruitment process,
                                            dashboards and much more.. */}
                                                
                                            Employee Onboarding / Relieving made easier with our workflow. It helps to track and analyze different
                                                employee information such as organization structure, timesheet, leave management, dashboards and much more...
                                        {/* (SOP-68) Web Page - Features - Content Changes - TS ( commented and included) - end */}

                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                            </Grid>
                            

                            {/* SOP-68 - Web Page - Features - Content Changes) - TS*/}
                            <Grid
                            item
                            lg={2.9}
                            xs={12}
                            sm={6}
                            md={4}
                            display={'flex'}
                            justifyContent={'center'}
                        >
                            <Card sx={{ maxWidth:{ 
                                lg:350,
                                xl:430,
                                xs:400
                            }, height: '100%' }}>
                                <CardActionArea sx={{ minHeight: 400}}>
                                    <Box display={'flex'} justifyContent={'center'}>
                                        <img
                                            component="img"
                                            
                                            // Changes done by TS
                                            // height="150"
                                            // width={'auto'}
                                                
                                            height = 'auto'
                                            width = '56%'
                                            src={doc1}
                                            alt="HR Management"
                                            style={{
                                                padding: 3
                                            }}
                                        />
                                    </Box>
                                    <CardContent sx={{ flexGrow: 1, minHeight: 100 }}> {/* Added minimum height and flex-grow */}
                                        <Typography display={'flex'} justifyContent={'center'} fontWeight={'600'}
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                            fontFamily={'Segoe UI'}
                                            color={'#4169e1'}
                                        >
                                           Document Management
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            fontSize={'1rem'}
                                            color="black"
                                            fontFamily={'Segoe UI'}
                                            >
                                                
                                       
                                                
                                                Everything related to document Creation and Management. All documents are categorized per functions along with security write protection etc.

                                                Smartopz DocPoint enhances collaboration, communication within project teams, reduces paperwork, and ensures efficient access to critical information like HR policy, IT Knowledge Base all in one place.
                                                

                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                            </Grid>
                            {/*// SOP-68 - Web Page - Features - Content Changes) - TS */}
                    </Grid>
                </Box>
               </Box>
                
            </Box>
        </Box>
    )
}

export default ProductsPage;
