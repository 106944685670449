
// import { useState,useRef,useEffect } from 'react'; // Import useState hook
// import React from 'react';
// import {
//     Box,
//     Button,
//     TextField,
//     InputAdornment,
//     Avatar,
//     useMediaQuery,
//     Menu,
//     MenuItem,
//     Grid,
// } from '@mui/material';
// import './Home.css';
// import { styled as muiStyled, alpha, useTheme } from '@mui/material/styles';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import InputBase from '@mui/material/InputBase';
// import MenuIcon from '@mui/icons-material/Menu';
// import SearchIcon from '@mui/icons-material/Search';
// // import ScreeBackGround from '../Assests/Wave1.jpg';
// import ScreeBackGround from '../Assests/Wave1.png';
// import Logo from '../Assests/SmartOpz_Logo.jpg';
// import HomeRightSideImage from '../Assests/HomeSideImg.png';
// import styled, { keyframes } from 'styled-components';
// import { pulse } from 'react-animations';
// import ContactUsPage from '../ContactUs/ContactUs';
// import LoginIcon from '@mui/icons-material/Login';
// import Popover from '@mui/material/Popover';
// import {useNavigate} from 'react-router-dom'
// import PricingPage from '../Pricing/Pricing';
// import Footer from '../Footer/Footer';
// import Dialog from '@mui/material/Dialog';
// import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
// import SignUpBG from '../Assests/Wave1.png';
// import axios from "axios";
// import HomeSignUpPage from './HomeSignUp';
// import ProductsPage from '../Products/Products';
// import SmartOpzImg from '../Assests/SmartOpz.jpg';




// const HomePage = () => {


//     // const navigate = useNavigate();
//     const theme = useTheme();
//     const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//     // UseState
//     const [BurgueMenuOpen, setBurgueMenuOpen] = useState(null); // State for menu anchor element
//     const [signUpOpen, setSignUpOpen] = React.useState(false);
//     const [Product, setProduct] = React.useState(null);
   

//     const handleSignUpOpen = () => {
//       setSignUpOpen(true);
//     };
  
//     const handleSignUpClose = () => {
//       setSignUpOpen(false);
//     };

//     const planTypeOptions =[
//         { label: 'Free', value:'Free' },
//         { label: 'Standard', value:'Standard'},
//         { label: 'Enterprise', value:'Enterprise'},
//     ]


//     const handleMenuOpen = (event) => {
//         setBurgueMenuOpen(event.currentTarget); // Open menu
//     };

//     const handleMenuClose = () => {
//         setBurgueMenuOpen(null); // Close menu
//     };

//     // Scroll
//     const homeRef = useRef(null);
//     const productRef = useRef(null);
//     const assetManagementRef = useRef(null);
//     const ticketingRef = useRef(null);
//     const hrManagement = useRef(null);
//     const contactUsRef = useRef(null);
//     const pricingRef = useRef(null);

//   const scrollToHome = () => {
//     homeRef.current.scrollIntoView({ behavior: 'smooth' });
//   };

//   const scrollToProductPage = () => {
//     productRef.current.scrollIntoView({ behavior: 'smooth' });
//   };

//   const scrollToAssetManagement = () => {
//     assetManagementRef.current.scrollIntoView({ behavior: 'smooth' });
//   };

//   const scrollToTicketing = () => {
//     ticketingRef.current.scrollIntoView({ behavior: 'smooth' });
//   };

//   const scrollToHrManagement = () => {
//     hrManagement.current.scrollIntoView({ behavior: 'smooth' });
//   }

//   const scrollToContactUs = () => {
//     contactUsRef.current.scrollIntoView({ behavior: 'smooth' });
//   };

//   const scrollToPricing = () => {
//     pricingRef.current.scrollIntoView({ behavior: 'smooth' });
//   };

// //   Product menu

   

// const handleLoginButtonClick = () => {
//     // Modified by sanjana SOP-15 to add Smartopz page link
//     // const SmartOpzLink = 'http://localhost:3003/';
//     const SmartOpzLink = `${process.env.REACT_APP_SMARTOPZ_URL}`;
//     // End of SOP-15
//     window.open(SmartOpzLink, '_blank');
//   };


//   const GetPlanDataById = async () => {

//     const getPlanDataPayload ={
//         Planid:1
//     }
    
//     try {
//       const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/plan/list_plan_by_id`,getPlanDataPayload);
//       ////console.log(res.data.data);
//     } catch (err) {
//       //console.log( "co",err);
//     }
//   };

//   const GetPlanData = async () => {

//     ////console.log("process.env.REACT_APP_BACKEND_URL",`${process.env.REACT_APP_BACKEND_URL}/plan/listplan`)
    
//     try {
//       const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/plan/listplan`);
//       ////console.log(res.data.data);
//     } catch (err) {
//       //console.log( "co",err);
//     }
//   };


//     useEffect(() => {
//         GetPlanDataById()
//         GetPlanData()
//     }, []);


//     return (
//       <Box>
//       <Box className='HomeMainDiv' ref={homeRef} p={5}
//             sx={{
//                 // backgroundImage: `url(${ScreeBackGround})`,
//                 // backgroundRepeat: 'no-repeat',
//                 // backgroundSize: 'cover',
//                 // backgroundPosition: 'center',
//                 height:{
//                     lg:'90vh',
//                     xs:'130vh',
//                     xl:'85vh'
//                 },
//             }}>

//                 <Box>
//                     <AppBar position="fixed" id='AppBar' width={'98vw'}  sx={{p:1, bgcolor:'white', display:'flex', justifyContent:'center'}} elevation={1}>
//                         <Toolbar  sx={{ backgroundColor: 'white', color: 'black'}}>
//                             <Grid container>
//                                 <Grid item lg={3} md={3} display={'flex'} justifyContent={'flex-end'} xs={6}>
//                                     <Box display={'flex'}>
//                                         <Button
//                                             size="large"
//                                             edge="start"
//                                             color="inherit"
//                                             aria-label="open drawer"
//                                             sx={{ mr: 2 ,
//                                                 '&:hover': {
//                                                     backgroundColor:'white'
//                                                 },
//                                             }}
//                                             onClick={() =>{
//                                                 scrollToHome();
//                                                 window.scrollTo(0, 0);
//                                                 }}
//                                         >
//                                             <img src={Logo} alt='Logo'
//                                             width={190} height={50}/>
//                                         </Button>
//                                     </Box>
//                                 </Grid>
//                                 <Grid item lg={5.5} md={6} xs={3} mt={1} display={'flex'} justifyContent={'flex-start'}>
//                                         {isMobile ? (
//                                             <Box width={'14.5rem'} mb={2} display={'flex'} justifyContent={'flex-end'}>
//                                                 <IconButton
//                                                     size="large"
//                                                     edge="start"
//                                                     color="inherit"
//                                                     aria-label="open drawer"
//                                                     onClick={handleMenuOpen} // Open menu on click
//                                                 >
//                                                     <MenuIcon />
//                                                 </IconButton>
//                                             </Box>
//                                         ) : (
//                                             <>
//                                             <Box  ml={3} p={1}>
//                                                 <Typography
//                                                     variant="h6"
//                                                     noWrap
//                                                     component="div"
//                                                     sx={{ flexGrow: 1, cursor: 'pointer',
//                                                     '&:hover': {
//                                                         color:'#4169e1'
//                                                     },
//                                                     fontFamily:'Segoe UI',
//                                                 }}
//                                                 // onMouseEnter={handleProductClick}
//                                                 onClick={()=>{
//                                                     scrollToProductPage();
//                                                 }}
//                                                 >
//                                                     Features
//                                                 </Typography>
//                                             </Box>
//                                             <Box ml={3} p={1}>
//                                             <Typography
//                                                 variant="h6"
//                                                 noWrap
//                                                 component="div"
//                                                 sx={{ flexGrow: 1, cursor: 'pointer',
//                                                 '&:hover': {
//                                                     color:'#4169e1'
//                                                 },
//                                                 fontFamily:'Segoe UI', }}
//                                                 onClick={scrollToContactUs}
//                                             >
//                                                 Contact us
//                                             </Typography>
//                                             </Box>

//                                             <Box ml={3} p={1}>
//                                             <Typography
//                                                 variant="h6"
//                                                 // noWrap
//                                                 // component="div"
//                                                 sx={{ flexGrow: 1, cursor: 'pointer',
//                                                 '&:hover': {
//                                                     color:'#4169e1'
//                                                 },
//                                                 fontFamily:'Segoe UI', }}
//                                                 onClick={scrollToPricing}
//                                             >
//                                                 Pricing
//                                             </Typography>

//                                             </Box>

//                                             <Box ml={3} p={1}>
//                                             <Button
//                                                 sx={{
//                                                     cursor: 'pointer',
//                                                     '&:hover': {
//                                                         color: '#4169e1'
//                                                     },
//                                                     fontFamily: 'Segoe UI',
//                                                     height: 40,
//                                                     width: 130,
//                                                     border: '2px solid #FFAB00',
//                                                     borderRadius: 1
//                                                 }}
//                                                 onClick={handleSignUpOpen}
//                                             >
//                                                 Try now
//                                             </Button>
//                                             </Box>

                                            
  

//                                             {/* feature popper  */}
//                                                 {/* <Popover
//                                                     id={Productid}
//                                                     open={Productopen}
//                                                     anchorEl={Product}
//                                                     onClose={handleProductClose}
//                                                     anchorOrigin={{
//                                                     vertical: 'bottom',
//                                                     horizontal: 'center',
//                                                     }}
//                                                     transformOrigin={{
//                                                         vertical: 'top',
//                                                         horizontal: 'center',
//                                                       }}
//                                                 >
//                                                     <Box width={180} p={2} onMouseLeave={handleProductClose}>
//                                                         <Box>
//                                                             <Typography sx={{ p: 0.7, cursor:'pointer', fontSize:'1.2rem', fontFamily:'Segoe UI',
//                                                             '&:hover': {
//                                                                 color:'#4169e1'
//                                                             },
                                                            
//                                                         }}
//                                                             onClick={() =>{
//                                                                 scrollToAssetManagement();
//                                                                 handleProductClose();
//                                                             }}
//                                                             >
//                                                                 Asset Management
//                                                             </Typography>
//                                                         </Box>
//                                                         <Box>
//                                                             <Typography sx={{ p: 0.7, cursor:'pointer', fontSize:'1.2rem','&:hover': {
//                                                                 color:'#4169e1'
//                                                             }, fontFamily:'Segoe UI' }}
//                                                             onClick={() =>{
//                                                                 scrollToTicketing();
//                                                                 handleProductClose();
//                                                             }}
//                                                             >
//                                                                 Ticketing System
//                                                             </Typography>
//                                                         </Box>
//                                                         <Box>
//                                                             <Typography sx={{ p: 0.7, cursor:'pointer', fontSize:'1.2rem','&:hover': {
//                                                                 color:'#4169e1'
//                                                             }, fontFamily:'Segoe UI' }}
//                                                             onClick={() =>{
//                                                                 scrollToHrManagement();
//                                                                 handleProductClose();
//                                                             }}
//                                                             >
//                                                                 HR Management
//                                                             </Typography>
//                                                         </Box>
//                                                     </Box>
//                                                 </Popover> */}
//                                             </>
//                                         )}
//                                 </Grid>
//                                 <Grid item lg={3.5} md={3}  display={'flex'} justifyContent={'center'}>
//                                     {isMobile ? null : (
//                                         <Button
//                                                     sx={{display:'flex',
//                                                         '&:hover': {
//                                                             color:'#4169e1',
//                                                             boxShadow:'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',

//                                                         },
//                                                         mt:{
//                                                            md:2
//                                                         }
//                                                     }}
//                                                     onClick={() =>{
//                                                         handleLoginButtonClick()
//                                                     }}
//                                                     >
//                                                     <Typography
//                                                         variant="h6"
//                                                         noWrap
//                                                         component="div"
//                                                         sx={{ flexGrow: 1, cursor: 'pointer'
//                                                     }}
//                                                     fontFamily={'Segoe UI'}
//                                                     >
//                                                     Login
//                                                     </Typography> <LoginIcon sx={{ml:1 }} />
//                                                     </Button>
//                                     )}
                                
//                                 </Grid>
//                             </Grid>

//                             {/* Menu for mobile view */}
//                             <Menu
//                                 anchorEl={BurgueMenuOpen}
//                                 open={Boolean(BurgueMenuOpen)}
//                                 onClose={handleMenuClose}
//                             >
//                                         {/* <Accordion elevation={0}>
//                                         <AccordionSummary
//                                         expandIcon={<ExpandMoreIcon />}
//                                         aria-controls="panel1-content"
//                                         id="panel1-header"
//                                         >
//                                             <Typography  fontFamily={'Segoe UI'}>
//                                                 Features
//                                             </Typography>
                                            
//                                         </AccordionSummary>
//                                         <Typography sx={{ p: 2, cursor:'pointer',
//                                         '&:hover': {
//                                             color:'#4169e1'
//                                         },
                                        
//                                     }}
//                                         onClick={() =>{
//                                             scrollToAssetManagement();
//                                             handleMenuClose();
//                                         }}
//                                         fontFamily={'Segoe UI'}
//                                         >
//                                             Asset Management
//                                         </Typography>
//                                         <Typography sx={{ p: 2, cursor:'pointer','&:hover': {
//                                             color:'#4169e1'
//                                         }, }}
//                                         fontFamily={'Segoe UI'}
//                                         onClick={() =>{
//                                             scrollToTicketing();
//                                             handleMenuClose();
//                                         }}
//                                         >
//                                             Ticketing System
//                                         </Typography>
//                                         <Typography sx={{ p: 2, cursor:'pointer','&:hover': {
//                                             color:'#4169e1'
//                                         }, }}
//                                         onClick={() =>{
//                                             scrollToHrManagement();
//                                             handleMenuClose();
//                                         }}
//                                         fontFamily={'Segoe UI'}
//                                         >
//                                             HR Management
//                                         </Typography>
                                        
//                                     </Accordion> */}
//                                 <MenuItem onClick={() =>{
//                                             scrollToProductPage();
//                                             handleMenuClose();
//                                         }} sx={{'&:hover': {color:'#4169e1'}, fontFamily:'Segoe UI'}} >Features</MenuItem>

//                                 <MenuItem onClick={() =>{
//                                             scrollToContactUs();
//                                             handleMenuClose();
//                                         }} sx={{'&:hover': {color:'#4169e1'}, fontFamily:'Segoe UI'}} >Contact us</MenuItem>
//                                 <MenuItem onClick={() =>{
//                                             scrollToPricing();
//                                             handleMenuClose();
//                                         }} sx={{'&:hover': {color:'#4169e1'}, fontFamily:'Segoe UI'}}>Pricing</MenuItem>
//                                 <MenuItem onClick={() =>{
//                                             scrollToHrManagement();
//                                             handleMenuClose();
//                                         }} sx={{'&:hover': {color:'#4169e1'}, fontFamily:'Segoe UI'}}>Try now</MenuItem>
//                                 <MenuItem onClick={() =>{
//                                             handleLoginButtonClick();
//                                             handleMenuClose();
//                                         }} sx={{'&:hover': {color:'#4169e1'}, fontFamily:'Segoe UI'}}>Login</MenuItem>
//                             </Menu>
//                         </Toolbar>
//                     </AppBar>
//                 </Box>

//                 <Box height={'90%'} width={'100%'} >
//                     <Grid container mt={15} display={'flex'} justifyContent={'center'}>
//                     <Grid  item xl={4} lg={6} md={6} xs={12}>
//                         <Box p={2} display={'flex'} justifyContent={'center'}flexDirection={'column'}>
//                             <Box mb={2} sx={{
//                                 paddingRight:{
//                                     xs:0
//                                 }
//                             }} display={'flex'} justifyContent={'center'}>
//                                 <img src={SmartOpzImg} alt='SmartOpz' height={100} width={'auto'}/>
//                             </Box>
//                             <Typography display={'flex'} justifyContent={'center'}
//                                 sx={{
//                                     fontSize: {
//                                         xs: '1.5rem',
//                                         md: '2.5rem',
//                                         lg: '40px',
//                                         xl: '42px'
//                                     },
//                                     color: '#4169e1',
//                                     fontWeight: 'bold',
//                                     fontFamily: 'Segoe UI'
//                                 }}
//                                 fontSize={'3rem'}
//                                 mt={{
//                                     lg: 0,
//                                     xs: 3
//                                 }}
//                             >
//                                 IT Managed Services
//                             </Typography>
//                             <Box>
//                             <Typography
//                                 sx={{
//                                     fontSize: {
//                                         xs: '1rem',
//                                         md: '1.2rem',
//                                         lg: '1.3rem',
//                                         xl: '1.5rem'
//                                     },
//                                     fontFamily: 'Segoe UI'
//                                 }}
//                             >
//                                 Our <span style={{fontWeight:'bold'}}>Smart Operations </span>  (Smartopz) serve to startup, small and medium companies.
//                             </Typography>

//                             <Typography mt={3}
//                                 sx={{
//                                     fontSize: {
//                                         xs: '1rem',
//                                         md: '1.2rem',
//                                         lg: '1.3rem',
//                                         xl: '1.5rem'
//                                     },
//                                     fontFamily: 'Segoe UI'
//                                 }}
//                             >
//                              Your business is unique, and so is your inventory.
//                               Stop worrying about asset tracking in excel sheet.
//                               Features include Asset Management, Ticketing system,
//                               HR management, Approval flow and much more..
//                               </Typography>

//                             </Box>
//                             <Box mt={3} display={'flex'} justifyContent={'center'}>
//                                 <Button
//                                     sx={{
//                                         cursor: 'pointer',
//                                         '&:hover': {
//                                             color: '#4169e1'
//                                         },
//                                         fontFamily: 'Segoe UI',
//                                         height: 40,
//                                         width: 130,
//                                         border: '2px solid #FFAB00',
//                                         borderRadius: 1
//                                     }}
//                                     onClick={handleSignUpOpen}
//                                 >
//                                     Try now
//                                 </Button>
//                             </Box>
//                         </Box>
//                     </Grid>


//                         <Grid item xl={5.5} lg={4}md={6} xs={12} display={'flex'} justifyContent={'flex-end'}
//                         >
//                             <Box pr={10} mt={10} >
//                             <img
//                                 style={{
//                                 maxWidth: '100%',
//                                 height: 'auto',
//                                 }}
//                                 src={HomeRightSideImage}
//                                 alt='HomeRightSideImage'
//                                 width={500}
//                                 height={500}
//                                 className='responsive-image'
//                             />
//                             </Box>
//                         </Grid>
//                     </Grid>
//                 </Box>


            
//         </Box>

//         {/* All Products page */}
//         <Box mt={2} mb={2}  ref={productRef}>
//             <ProductsPage/>
//         </Box>

//         {/* Contact page */}
//         <Box mt={2} mb={2}  ref={contactUsRef}
//         >
//           <ContactUsPage/>
//         </Box>

//         {/* Pricing */}
//         <Box mt={2} mb={2}  ref={pricingRef}
//         >
//           <PricingPage/>
//         </Box>

//         {/* Footer */}
//         <Box mt={2} mb={2}
//         >
//             <Footer/>
//         </Box>
        
//         <Box>
//             <Dialog  open={signUpOpen} fullWidth>
//                 <HomeSignUpPage handleSignUpClose={handleSignUpClose}/>
//             </Dialog>

//         </Box>
        

//       </Box>
        
//     )
// }

// export default HomePage;





//  commented and included by Teena ( (SOP-68) Web Page - Features - Content Changes)

// imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Card,
} from '@mui/material';
import './Home.css';
import { styled as muiStyled, alpha, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import ScreeBackGround from '../Assests/Wave1.png';
import Logo from '../Assests/SmartOpz_Logo.jpg';
import HomeRightSideImage from '../Assests/HomeSideImg.png';
import styled, { keyframes } from 'styled-components';
import { pulse } from 'react-animations';
import ContactUsPage from '../ContactUs/ContactUs';
import LoginIcon from '@mui/icons-material/Login';
import Popover from '@mui/material/Popover';
import {useNavigate} from 'react-router-dom'
import PricingPage from '../Pricing/Pricing';
import Footer from '../Footer/Footer';
import Dialog from '@mui/material/Dialog';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import SignUpBG from '../Assests/Wave1.png';
import axios from "axios";
import HomeSignUpPage from './HomeSignUp';
import ProductsPage from '../Products/Products';
import SmartOpzImg from '../Assests/SmartOpz.jpg';

import Carousel from "react-material-ui-carousel"; // Carousel
import banner_left from "../Assests/banner_left.png"
import banner_right from "../Assests/banner_right.png"

import banner from "../Assests/banner.png";

const HomePage = () => {

    // const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    // UseState
    const [BurgueMenuOpen, setBurgueMenuOpen] = useState(null); // State for menu anchor element
    const [signUpOpen, setSignUpOpen] = React.useState(false);
    const [Product, setProduct] = React.useState(null);

    // SOP-68 Web Page - Features - Content Changes- TS
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleSignUpOpen = () => {
      setSignUpOpen(true);
    };
  
    const handleSignUpClose = () => {
      setSignUpOpen(false);
    };

    const planTypeOptions =[
        { label: 'Free', value:'Free' },
        { label: 'Standard', value:'Standard'},
        { label: 'Enterprise', value:'Enterprise'},
    ]

    const handleMenuOpen = (event) => {
        setBurgueMenuOpen(event.currentTarget); // Open menu
    };

    const handleMenuClose = () => {
        setBurgueMenuOpen(null); // Close menu
    };

    // Scroll 
    const homeRef = useRef(null);
    const productRef = useRef(null);
    const assetManagementRef = useRef(null);
    const ticketingRef = useRef(null);
    const hrManagement = useRef(null);
    const contactUsRef = useRef(null);
    const pricingRef = useRef(null);

  const scrollToHome = () => {
    homeRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToProductPage = () => {
    productRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToAssetManagement = () => {
    assetManagementRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToTicketing = () => {
    ticketingRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToHrManagement = () => {
    hrManagement.current.scrollIntoView({ behavior: 'smooth' });
  }

  const scrollToContactUs = () => {
    contactUsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToPricing = () => {
    pricingRef.current.scrollIntoView({ behavior: 'smooth' });
  };

//   Product menu

   

const handleLoginButtonClick = () => {
    // Modified by sanjana SOP-15 to add Smartopz page link
    // const SmartOpzLink = 'http://localhost:3003/';
    const SmartOpzLink = `${process.env.REACT_APP_SMARTOPZ_URL}`;
    // End of SOP-15
    window.open(SmartOpzLink, '_blank');
  };


  const GetPlanDataById = async () => {

    const getPlanDataPayload ={
        Planid:1
    }
    
    try {
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/plan/list_plan_by_id`,getPlanDataPayload);
      ////console.log(res.data.data);
    } catch (err) {
      //console.log( "co",err);
    }
  };

  const GetPlanData = async () => {

    ////console.log("process.env.REACT_APP_BACKEND_URL",`${process.env.REACT_APP_BACKEND_URL}/plan/listplan`)
    
    try {
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/plan/listplan`);
      ////console.log(res.data.data);
    } catch (err) {
      //console.log( "co",err);
    }
  };


    // (SOP-68) Web Page - Features - Content Changes by TS - Start

    function Item({ item }) {
        return (
            <div className="carousel-item" style={{ width: "100%", height: "100%" }}>{item.description}</div>
        );
      }
      
        const items = [
          {
            name: "",
                description: 
                <Box>
                    {/* <Box mb={2} sx={{
                        paddingRight:{
                            xs:0
                        }
                    }} display={'flex'} justifyContent={'center'}>
                            <img src={SmartOpzImg} alt='SmartOpz' height={100} width={'auto'} sx={{backgroundColor:'#E6FEFF'}} />
                    </Box> */}
                    {/* <Typography display={'flex'} justifyContent={'center'}
                        sx={{
                            fontSize: {
                                xs: '1.5rem',
                                md: '2.5rem',
                                // lg: '40px',
                                lg: '35px',
                                xl: '42px'
                            },
                            color: '#4169e1',
                            fontWeight: 'bold',
                            fontFamily: 'Segoe UI'
                        }}
                        fontSize={'3rem'}
                        mt={{
                            lg: 0,
                            xs: 3
                        }}
                        >
                           
                            BackOffice to IT Services Management all in one Portal...
                    </Typography> */}
                    <Box>
                    <Typography
                        sx={{
                            fontSize: {
                                xs: '1rem',
                                md: '1.2rem',
                                lg: '1.3rem',
                                xl: '1.5rem'
                            },
                            fontFamily: 'Segoe UI'
                        }}
                            >
                                {/*  (SOP-68) Web Page - Features - Content Changes by TS */}
                                {/* Our <span style={{fontWeight:'bold'}}>Smart Operations </span>  (Smartopz) serve to startup, small and medium companies. */}
                                Our <span style={{fontWeight:'bold'}}>Smart Operations </span>   (Smartopz) helps startup, small and medium companies with Digital Transformation.
                   
                    </Typography>

                    <Typography mt={3}
                        sx={{
                            fontSize: {
                                xs: '1rem',
                                md: '1.2rem',
                                lg: '1.3rem',
                                xl: '1.5rem'
                            },
                            fontFamily: 'Segoe UI'
                        }}
                            >
                    </Typography>

                    </Box>
                    {/* <Box mt={3} display={'flex'} justifyContent={'center'}>
                        <Button
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    color: '#4169e1'
                                },
                                fontFamily: 'Segoe UI',
                                height: 40,
                                width: 130,
                                border: '2px solid #FFAB00',
                                borderRadius: 1
                            }}
                            onClick={handleSignUpOpen}
                        >
                            Try now
                        </Button>
                    </Box> */}
                </Box>
          },
          {
            name: "",
            description:    <Box>
                {/* <Box mb={2}
                    sx={{
                    paddingRight:{
                    xs:0
                }
            }} display={'flex'} justifyContent={'center'}>
                <img src={SmartOpzImg} alt='SmartOpz' height={100} width={'auto'} sx={{backgroundColor:'#E6FEFF'}} />
            </Box>
            <Typography display={'flex'} justifyContent={'center'}
                sx={{
                    fontSize: {
                        xs: '1.5rem',
                        md: '2.5rem',
                        // lg: '40px',
                        lg: '35px',
                        xl: '42px'
                    },
                    color: '#4169e1',
                    fontWeight: 'bold',
                    fontFamily: 'Segoe UI'
                }}
                fontSize={'3rem'}
                mt={{
                    lg: 0,
                    xs: 3
                }}
                >
                    
                    {/* (SOP-68) Web Page - Features - Content Changes by TS */}
                    {/* IT Managed Services */}
                    {/* BackOffice to IT Services Management all in one Portal...
            </Typography> */} 
            <Box>
            <Typography
                sx={{
                    fontSize: {
                        xs: '1rem',
                        md: '1.2rem',
                        lg: '1.3rem',
                        xl: '1.5rem'
                    },
                    fontFamily: 'Segoe UI'
                }}
            >
                {/*  (SOP-68) Web Page - Features - Content Changes by TS */}
                {/* Our <span style={{fontWeight:'bold'}}>Smart Operations </span>  (Smartopz) serve to startup, small and medium companies. */}
                Your business is unique, and so is your requirement. Stop worrying about excel sheet tracking.
                Streamline, Simplify and automate your Backoffice Management.
           
            </Typography>

            <Typography mt={3}
                sx={{
                    fontSize: {
                        xs: '1rem',
                        md: '1.2rem',
                        lg: '1.3rem',
                        xl: '1.5rem'
                    },
                    fontFamily: 'Segoe UI'
                }}>
            </Typography>

            </Box>
            {/* <Box mt={3} display={'flex'} justifyContent={'center'}>
                <Button
                    sx={{
                        cursor: 'pointer',
                        '&:hover': {
                            color: '#4169e1'
                        },
                        fontFamily: 'Segoe UI',
                        height: 40,
                        width: 130,
                        border: '2px solid #FFAB00',
                        borderRadius: 1
                    }}
                    onClick={handleSignUpOpen}
                >
                    Try now
                </Button>
            </Box> */}
        </Box>
  
            }
            ,
            {
              name: "",
              description:    <Box>
              {/* <Box mb={2} sx={{
                  paddingRight:{
                      xs:0
                  }
              }} display={'flex'} justifyContent={'center'}>
                  <img src={SmartOpzImg} alt='SmartOpz' height={100} width={'auto'} sx={{backgroundColor:'#E6FEFF'}} />
              </Box>
              <Typography display={'flex'} justifyContent={'center'}
                  sx={{
                      fontSize: {
                          xs: '1.5rem',
                          md: '2.5rem',
                          // lg: '40px',
                          lg: '35px',
                          xl: '42px'
                      },
                      color: '#4169e1',
                      fontWeight: 'bold',
                      fontFamily: 'Segoe UI'
                  }}
                  fontSize={'3rem'}
                  mt={{
                      lg: 0,
                      xs: 3
                  }}
                  >
                      
                      {/* (SOP-68) Web Page - Features - Content Changes by TS */}
                      {/* IT Managed Services */}
                      {/* BackOffice to IT Services Management all in one Portal...
              </Typography> */}
              <Box>
              <Typography
                  sx={{
                      fontSize: {
                          xs: '1rem',
                          md: '1.2rem',
                          lg: '1.3rem',
                          xl: '1.5rem'
                      },
                      fontFamily: 'Segoe UI'
                  }}
                      >
                          {/*  (SOP-68) Web Page - Features - Content Changes by TS */}
                          {/* Our <span style={{fontWeight:'bold'}}>Smart Operations </span>  (Smartopz) serve to startup, small and medium companies. */}
                          Smartopz features include Asset Management, Ticketing system, HR management, Document handling,
                          Approval flow and much more..
             
              </Typography>
  
              <Typography mt={3}
                  sx={{
                      fontSize: {
                          xs: '1rem',
                          md: '1.2rem',
                          lg: '1.3rem',
                          xl: '1.5rem'
                      },
                      fontFamily: 'Segoe UI'
                  }}
                      >
  
                </Typography>
  
              </Box>
              {/* <Box mt={3} display={'flex'} justifyContent={'center'}>
                  <Button
                      sx={{
                          cursor: 'pointer',
                          '&:hover': {
                              color: '#4169e1'
                          },
                          fontFamily: 'Segoe UI',
                          height: 40,
                          width: 130,
                          border: '2px solid #FFAB00',
                          borderRadius: 1
                      }}
                      onClick={handleSignUpOpen}
                  >
                      Try now
                  </Button>
              </Box> */}
          </Box>
    
            } ,
        //     {
        //       name: "",
        //       description:    <Box>
        //       {/* <Box mb={2} sx={{
        //           paddingRight:{
        //               xs:0
        //           }
        //       }} display={'flex'} justifyContent={'center'}>
        //           <img src={SmartOpzImg} alt='SmartOpz' height={100} width={'auto'} sx={{backgroundColor:'#E6FEFF'}} />
        //       </Box>
        //       <Typography display={'flex'} justifyContent={'center'}
        //           sx={{
        //               fontSize: {
        //                   xs: '1.5rem',
        //                   md: '2.5rem',
        //                   // lg: '40px',
        //                   lg: '35px',
        //                   xl: '42px'
        //               },
        //               color: '#4169e1',
        //               fontWeight: 'bold',
        //               fontFamily: 'Segoe UI'
        //           }}
        //           fontSize={'3rem'}
        //           mt={{
        //               lg: 0,
        //               xs: 3
        //           }}
        //           >
                      
        //               {/* (SOP-68) Web Page - Features - Content Changes by TS */}
        //               {/* IT Managed Services */}
        //               {/* BackOffice to IT Services Management all in one Portal...
        //       </Typography> */} 
        //       <Box>
        //       <Typography
        //           sx={{
        //               fontSize: {
        //                   xs: '1rem',
        //                   md: '1.2rem',
        //                   lg: '1.3rem',
        //                   xl: '1.5rem'
        //               },
        //               fontFamily: 'Segoe UI'
        //           }}
        //               >
        //                 Smartopz DocPoint enhances collaboration, communication within project teams, reduces paperwork, and ensures efficient access to critical information like HR policy, IT Knowledge Base all in one place easy to create and access.
        //                 Offers features like security, classification and streamlined search.
             
        //       </Typography>
  
        //       <Typography mt={3}
        //           sx={{
        //               fontSize: {
        //                   xs: '1rem',
        //                   md: '1.2rem',
        //                   lg: '1.3rem',
        //                   xl: '1.5rem'
        //               },
        //               fontFamily: 'Segoe UI'
        //           }}
        //               >
  
        //         </Typography>
  
        //       </Box>
        //       <Box mt={3} display={'flex'} justifyContent={'center'}>
        //           <Button
        //               sx={{
        //                   cursor: 'pointer',
        //                   '&:hover': {
        //                       color: '#4169e1'
        //                   },
        //                   fontFamily: 'Segoe UI',
        //                   height: 40,
        //                   width: 130,
        //                   border: '2px solid #FFAB00',
        //                   borderRadius: 1
        //               }}
        //               onClick={handleSignUpOpen}
        //           >
        //               Try now
        //           </Button>
        //       </Box>
        //   </Box>
    
        //     }
        ];
      

    const handleChange = (newIndex) => {
        // Determine the direction based on the index change
        if (newIndex > currentIndex) {
            setCurrentIndex(newIndex);
            // Optionally handle direction-related logic here
        }
    };
      
 
    useEffect(() => {
        GetPlanDataById()
        GetPlanData()
    }, []);


    return (
      <Box sx={{ backgroundColor:'#FAFAFA',}}>
        <Box className='HomeMainDiv' ref={homeRef} p={5} mb={2}
            sx={{
                // backgroundImage: `url(${ScreeBackGround})`,
                // backgroundRepeat: 'no-repeat',
                // backgroundSize: 'cover',
                // backgroundPosition: 'center',
                // height:{ 
                //     lg:'100%',
                //     xs:'130vh',
                //     xl:'85vh'
                // },
                padding: '0% 0% 0% 0%',
            
            }}>

                <Box>
                    <AppBar position="fixed" id='AppBar' width={'90vw'}  sx={{p:1, bgcolor:'white', display:'flex', justifyContent:'center'}} elevation={2}>
                        <Toolbar  sx={{backgroundColor: 'white', color: 'black'}}>
                            <Grid container>
                                <Grid item lg={3} md={3} display={'flex'} justifyContent={'flex-end'} xs={6}>
                                    <Box display={'flex'}>
                                        <Button
                                            size="large"
                                            edge="start"
                                            color="inherit"
                                            aria-label="open drawer"
                                            sx={{ mr: 2 , 
                                                '&:hover': {
                                                    backgroundColor:'white'
                                                },
                                            }}
                                            onClick={() =>{
                                                scrollToHome();
                                                window.scrollTo(0, 0);
                                                }} 
                                        >
                                            <img src={Logo} alt='Logo' 
                                            width={190} height={50}/>
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item lg={5.5} md={6} xs={3} mt={1} display={'flex'} justifyContent={'flex-start'} >
                                        {isMobile ? (
                                            <Box width={'14.5rem'} mb={2} display={'flex'} justifyContent={'flex-end'}>
                                                <IconButton
                                                    size="large" 
                                                    edge="start"
                                                    color="inherit"
                                                    aria-label="open drawer"
                                                    onClick={handleMenuOpen} // Open menu on click
                                                >
                                                    <MenuIcon />
                                                </IconButton>
                                            </Box>
                                        ) : (
                                            <>
                                            <Box  ml={3} p={1}>
                                                <Typography
                                                    variant="h6"
                                                    noWrap
                                                    component="div"
                                                    sx={{ flexGrow: 1, cursor: 'pointer',
                                                    '&:hover': {
                                                        color:'#4169e1'
                                                    },
                                                    fontFamily:'Segoe UI',
                                                }}
                                                // onMouseEnter={handleProductClick}
                                                onClick={()=>{
                                                    scrollToProductPage();
                                                }}
                                                >
                                                    Features
                                                </Typography>
                                            </Box>
                                            <Box ml={3} p={1}>
                                            <Typography
                                                variant="h6"
                                                noWrap
                                                component="div"
                                                sx={{ flexGrow: 1, cursor: 'pointer',
                                                '&:hover': {
                                                    color:'#4169e1'
                                                },
                                                fontFamily:'Segoe UI', }}
                                                onClick={scrollToContactUs}
                                            >
                                                Contact us
                                            </Typography>
                                            </Box>

                                            <Box ml={3} p={1}>
                                            <Typography
                                                variant="h6"
                                                // noWrap
                                                // component="div"
                                                sx={{ flexGrow: 1, cursor: 'pointer',
                                                '&:hover': {
                                                    color:'#4169e1'
                                                },
                                                fontFamily:'Segoe UI', }}
                                                onClick={scrollToPricing}
                                            >
                                                Pricing
                                            </Typography>

                                            </Box>

                                            <Box ml={3} p={1}>
                                            <Button
                                                sx={{
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        color: '#4169e1'
                                                    },
                                                    fontFamily: 'Segoe UI',
                                                    height: 40,
                                                    width: 130,
                                                    border: '2px solid #FFAB00',
                                                    borderRadius: 1
                                                }}
                                                onClick={handleSignUpOpen}
                                            >
                                                Try now
                                            </Button>
                                            </Box>
                                            </>
                                        )}                        
                                </Grid>
                                <Grid item lg={3.5} md={3}  display={'flex'} justifyContent={'center'}>
                                    {isMobile ? null : (
                                        <Button   
                                            sx={{display:'flex',
                                                '&:hover': {
                                                    color:'#4169e1',
                                                    boxShadow:'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
                                                },
                                                mt:{
                                                    md:2
                                                }
                                            }}
                                            onClick={() =>{
                                                handleLoginButtonClick()
                                            }}
                                            >
                                            <Typography
                                                variant="h6"
                                                noWrap
                                                component="div"
                                                sx={{ flexGrow: 1, cursor: 'pointer'
                                            }}
                                            fontFamily={'Segoe UI'}
                                            >
                                            Login
                                            </Typography> <LoginIcon sx={{ml:1 }} /> 
                                            </Button>                                 
                                    )} 
                                </Grid>
                            </Grid>

                            {/* Menu for mobile view */}
                            <Menu
                                anchorEl={BurgueMenuOpen}
                                open={Boolean(BurgueMenuOpen)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={() =>{
                                            scrollToProductPage();
                                            handleMenuClose();
                                        }} sx={{'&:hover': {color:'#4169e1'}, fontFamily:'Segoe UI'}} >Features</MenuItem>

                                <MenuItem onClick={() =>{
                                            scrollToContactUs();
                                            handleMenuClose();
                                        }} sx={{'&:hover': {color:'#4169e1'}, fontFamily:'Segoe UI'}} >Contact us</MenuItem>
                                <MenuItem onClick={() =>{
                                            scrollToPricing();
                                            handleMenuClose();
                                        }} sx={{'&:hover': {color:'#4169e1'}, fontFamily:'Segoe UI'}}>Pricing</MenuItem>
                                <MenuItem onClick={() =>{
                                            scrollToHrManagement();
                                            handleMenuClose();
                                        }} sx={{'&:hover': {color:'#4169e1'}, fontFamily:'Segoe UI'}}>Try now</MenuItem>
                                <MenuItem onClick={() =>{
                                            handleLoginButtonClick();
                                            handleMenuClose();
                                        }} sx={{'&:hover': {color:'#4169e1'}, fontFamily:'Segoe UI'}}>Login</MenuItem>
                            </Menu>
                        </Toolbar>
                    </AppBar>                    
                </Box>
            {/* 
                <Box height={'90%'} width={'100%'}>
                    <Grid container mt={15} display={'flex'} justifyContent={'center'}>
                        <Grid item xl={5.5} lg={6} md={6} xs={12} sx={{backgroundColor:'#E6FEFF'}}> 
                        <Box p={2} display={'flex'} justifyContent={'center'}flexDirection={'column'} sx={{borderLeft:'solid 1px #bfbfbf',borderRight:'solid 1px #bfbfbf'}}>
                            <Box mb={2} sx={{
                  paddingRight:{
                      xs:0
                  }
              }} display={'flex'} justifyContent={'center'}>
                  <img src={SmartOpzImg} alt='SmartOpz' height={100} width={'auto'} sx={{backgroundColor:'#E6FEFF'}} />
              </Box>
              <Typography display={'flex'} justifyContent={'center'}
                  sx={{
                      fontSize: {
                          xs: '1.5rem',
                          md: '2.5rem',
                          // lg: '40px',
                          lg: '35px',
                          xl: '42px'
                      },
                      color: '#4169e1',
                      fontWeight: 'bold',
                      fontFamily: 'Segoe UI'
                  }}
                  fontSize={'3rem'}
                  mt={{
                      lg: 0,
                      xs: 3
                  }}
                  >
                      
                      BackOffice to IT Services Management all in one Portal...
              </Typography>
                            <Carousel
                                index={currentIndex}
                                animation="slide"
                                autoPlay={true}
                                interval={6000}
                                // navButtonsAlwaysVisible={true}
                                indicators={true}
                                onChange={(newIndex) => handleChange(newIndex)}
                               sx={{height:'300px'}}
                            >
                                {items.map((item, i) => (
                                <Item key={i} item={item} />
                                ))}
                                </Carousel>
                                </Box>
                        </Grid>
                        <Grid item xl={5.5} lg={4}md={6} xs={12} display={'flex'} justifyContent={'flex-end'} sx={{ backgroundColor:'#C4E6E9'}} >
                            <Box pr={10} mt={10} sx={{ backgroundColor:'#C4E6E9'}} >
                                <img 
                                    style={{
                                        maxWidth: '100%',
                                        height: 'auto', 
                                        backgroundColor:'#C4E6E9'
                                    }} 
                                    src={HomeRightSideImage} 
                                    alt='HomeRightSideImage' 
                                    width={500} 
                                    height={500}
                                    className='responsive-image'
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box> */}




{/* <Box  sx={{
                // height: {
                //     xs: '235vh',
                //     lg: '10vh',
                //     xl: '80vh',
                //     md: '20vh'
                // },
                bgcolor: '#f8f8ff',
                padding:'10px 0px 0px 0px',
                marginBottom:'20px',
           
                justifyContent: 'center',
                }}>
                     <Grid
                        container
                        // display={'flex'}
                        alignItems={'stretch'}
                        justifyContent={'center'}
                        // mt={3}
                        // spacing={3}
                        fullWidth
                    >
                     {/* <Box > 
               <Box 
                alignItems={'center'} 
                justifyContent={'center'}
                // displayDirection={'column'} 
                > */}
                {/* <Grid container mt={15} display={'flex'} justifyContent={'center'}>
                    // <Grid item xl={5.5} lg={6} md={6} xs={12} >  */}
                        {/* <Box p={2} display={'flex'} flexDirection={'column'} >
                            <Box mb={2} sx={{
                                paddingRight:{
                                    xs:0
                                    }
                                }} display={'flex'} justifyContent={'center'}> */}
                                    {/* <Grid
                            item
                                // lg={4}
                                lg={12}
                            xs={12}
                            sm={12}
                            md={12}
                            // display={'flex'}
                            justifyContent={'center'}
                        >
                                <img 
                                    style={{
                                        // maxWidth: '1900',
                                        // height: 'auto', 
                                        // backgroundColor:'#C4E6E9'
                                        // justifyContent:'center',
                                    }} 
                                    src={banner} 
                                    alt='banner' 
                                    // width={1700}     
                                    // height={900}
                                    fullWidth
                                    className='responsive-image'

                                />
                                </Grid>
                            {/* </Box>
                        </Box> */}
                    {/* </Grid>  
                </Grid>          */}
                {/* </Box>
                </Box> */}
                {/* </Grid>
</Box> */} 

                







                {/* <Box mt={15} sx={{
                    // backgroundColor:'#FAFAFA',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
               }}> 
        <Box
            display={'flex'} 
            alignItems={'center'} 
            justifyContent={'center'}
            flexDirection={'column'} 
        >
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}flexDirection={'column'}>        
                <Grid
                    container
                    display={'flex'}
                    alignItems={'stretch'}
                    justifyContent={'center'}
                    // mt={3}
                    spacing={3}
                            >

                        <Grid item lg={6} xs={12}sm={12}md={12} display={'flex'} justifyContent={'center'}>
                            <img
                                component="img"
                                height="590"
                                width={'auto'}
                                src={banner_left}
                                alt="banner_left"
                                style={{
                                    padding: 3
                                }}
                            />
                        </Grid> 
                        {/* <Grid item lg={1} xs={1} sm={1} md={1} display={'flex'} justifyContent={'center'}>    </Grid>  */}
                                {/* <Grid item lg={5} xs={12} sm={12} md={12} display={'flex'} justifyContent={'center'}> */}
                        {/* <Grid item lg={6} xs={12}sm={12}md={12} display={'flex'} justifyContent={'center'}>
                            <Box>
                                <img
                                    component="img"
                                    height="450"
                                    width={'auto'}
                                    src={banner_right}
                                    alt="banner_right"
                                    style={{
                                        padding: '-50px',
                                        position: 'absolute',
                                    }}
                                />
                           
                            {/* <br></br> */}
                            {/* <Box>
                                <Carousel
                                        index={currentIndex}
                                        animation="slide"
                                        autoPlay={true}
                                        interval={6000}
                                        // navButtonsAlwaysVisible={true}
                                        indicators={true}
                                        onChange={(newIndex) => handleChange(newIndex)}
                                                    sx={{
                                                        height: '300px',
                                                        width: '450px',
                                                        position: '-webkit-sticky',
                                                        //  position: 'sticky',
                                                        // top: '45%',
                                                        // left: '58%',
                                                        // top: '3%',
                                                        left: '3%',
                                                        // transform: 'translate'('-50%', '-50%')
                                                        transform: 'revert-layer',
                                                        marginTop:'240px'
                                                    }}
                                    >
                                        {items.map((item, i) => (
                                        <Item key={i} item={item} />
                                        ))}
                                </Carousel> 
                                <Box mt={-7.5} ml={4} display={'flex'} justifyContent={'center'}>
                                    <Button
                                        sx={{
                                            cursor: 'pointer',
                                            '&:hover': {
                                                color: '#4169e1'
                                            },
                                            fontFamily: 'Segoe UI',
                                            height: 40,
                                            width: 130,
                                            border: '2px solid #FFAB00',
                                            borderRadius: 1,  
                                        }}
                                        onClick={handleSignUpOpen}
                                    >
                                        Try now
                                    </Button>
                                </Box>
                            </Box>       
                        </Box>                 
                    </Grid> 
                        
                </Grid>    
                </Box>
            </Box>
                </Box> */} 


            </Box>

            
        {/* included by TS */}
            <Box mt={20}
                sx={{
                backgroundColor:'#FAFAFA',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                padding:'0% 0% 0% 0%'
            }}> 
            <Grid
                container
                display={'flex'} justifyContent={'center'} sx={{ width: '100%', height:' auto'}}>

                <Grid item lg={6} xs={12} sm={12} md={12} sx={{  width: '100%', height: 'auto'}}>
                    <img
                        component="img"
                        src={banner_left}
                        alt="banner_left"
                        style={{
                            width: '80%',
                            height: ' auto',
                        justifyContent:'center',marginLeft:'10%',
                        }}
                    />
                </Grid> 
                       
                <Grid item lg={6} xs={12}sm={12}md={12} sx={{  width: '100%',}}>
                    <Box sx={{ height: '1%',    position:' relative' }} >
                        <img
                            component="img"
                            src={banner_right}
                            alt="banner_right"
                            style={{
                                justifyContent:'center',marginLeft:'15%',
                                alignItems:'center',
                                width: '70%',
                                position: 'absolute'
                            }}
                        />
                    </Box>
                    <Box sx={{}}>
                        <Carousel
                            index={currentIndex}
                            animation="slide"
                            autoPlay={true}
                            interval={6000}
                            indicators={true}
                            onChange={(newIndex) => handleChange(newIndex)}
                                sx={{
                                    height: 'auto',
                                    width: '70%',
                                    position: '-webkit-sticky',
                                    fontSize:'large',
                                    marginLeft:'18%',
                                    marginTop: '36%',
                                        marginBottom:'7%'
                                }}
                        >
                            {items.map((item, i) => (
                            <Item key={i} item={item} />
                            ))}
                        </Carousel> 
                        
                    </Box> 
                
                {/* </Card> */}  
                </Grid> 
                 
               
                </Grid>
               
                </Box>  
                <Grid
                    container
                    display={'flex'} justifyContent={'center'} sx={{ width: '100%', height:' auto',marginTop:'-5%'}}>
                        <Grid item lg={7.8} xs={0.5} sm={1.5} md={2} sx={{  width: '100%', height: 'auto'}}></Grid>
                            <Grid item lg={2.5} xs={3} sm={3} md={3} sx={{  width: '100%', height: 'auto'}}>
                            {/* <Box mt={-3.5}   justifyContent={'center'}> */}
                                    <Button
                                        sx={{
                                            cursor: 'pointer',
                                            '&:hover': {
                                                color: '#4169e1'
                                            },
                                            fontFamily: 'Segoe UI',
                                            height: 57,
                                            width: 140,
                                            border: '2px solid #FFAB00',
                                            position:'sticky',
                                            borderRadius: 1,  
                                            marginBottom:'17%'
                                        }}
                                        onClick={handleSignUpOpen}
                                    >
                                        Try now
                                    </Button>
                        {/* </Box> */}
                    </Grid>
                    </Grid>

                {/* included by TS */}


        
        {/* All Products page */}
        <Box mt={2} mb={2}  ref={productRef}>
            <ProductsPage/>
        </Box>

        {/* Contact page */}
        <Box mt={2} mb={2}  ref={contactUsRef} 
        >
          <ContactUsPage/>
        </Box>

        {/* Pricing */}
        <Box mt={2} mb={2}  ref={pricingRef} 
        >
          <PricingPage/>
        </Box>

        {/* Footer */}
        <Box mt={2} mb={2}  
        >
            <Footer/>
        </Box>
        
        <Box>
            <Dialog  open={signUpOpen} fullWidth>
                <HomeSignUpPage handleSignUpClose={handleSignUpClose}/>
            </Dialog>

        </Box>
      </Box>
        
    )
}

export default HomePage;
