import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Logo from '../Assests/SmartOpz_Logo.jpg';
import { useNavigate } from "react-router-dom";


const TermsAndConditionsPage =()=>{

    const navigate = useNavigate();

    useEffect(() => {
        // Scroll to the top of the page when component mounts
        window.scrollTo(0, 0);
    }, []);



    return(
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <AppBar position="fixed" id='AppBar' width={200} >
                <Toolbar  sx={{ backgroundColor: 'white', color: 'black', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                    <Box display={'flex'} mr={5}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{ mr: 2 , 
                                '&:hover': {
                                    backgroundColor:'white'
                                }, }}
                        >
                            <img src={Logo} alt='Logo' 
                            width={190} height={50}
                             onClick={()=>{
                                navigate('/')
                             }}
                              />
                        </IconButton>
                    </Box>
                        <Box justifyContent={'center'} display={'flex'} flex={0.8}>
                            <Typography
                                variant="h3"
                                sx={{fontSize:{
                                    xs:'2rem',
                                    lg:'3rem'
                                }}}
                            >
                                Terms & Conditions
                            </Typography>
                           
                        </Box>
                </Toolbar>
            </AppBar>

            <Box sx={{ mt: 12, width: '75%' }}>

                <Typography variant='h4' fontWeight={'bold'} >
                Terms and Conditions for Intopz Technologies Pvt Ltd (Smartopz)
                </Typography>

                <Box>
                <Typography mt={3} variant='h5' fontWeight={'bold'}>
                Introduction
                </Typography>

                <Typography fontSize={'1rem'}>
                These Website Standard Terms and Conditions written on this
                 webpage shall manage your use of our website, Smartopz accessible 
                 at www.smartopz.com.
                </Typography>  
                <Typography fontSize={'1rem'}>
                    These Terms will be applied fully and affect to your use of this Website.
                    By using this Website, you agreed to accept all terms and conditions written 
                    in here. You must not use this Website if you disagree with any of these
                    Website Standard Terms and Conditions. These Terms and Conditions have 
                    been generated with the help of the Terms And Conditiions Sample Generator.
                    Minors or people below 18 years old are not allowed to use this Website.
                </Typography>                  
                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Intellectual Property Rights
                </Typography>
                <Typography fontSize={'1rem'}>
                Other than the content you own, under these Terms, Intopz Technologies 
                Pvt Ltd and/or its licensors own all the intellectual property rights 
                and materials contained in this Website.You are granted limited license 
                only for purposes of viewing the material contained on this Website.
                </Typography>
                </Box>

                <Box mt={3}>
                <Typography  variant='h5' fontWeight={'bold'}>
                Restrictions
                </Typography>
                <Typography mt={2} variant='h6' fontWeight={'bold'}>
                You are specifically restricted from all of the following:
                </Typography>
                <Box>
                    <ul >
                    <li style={{fontSize:'1rem', fontFamily:'sans-serif'}}>
                    publishing any Website material in any other media;
                    </li>
                    <li style={{fontSize:'1rem', fontFamily:'sans-serif'}}>
                    selling, sublicensing and/or otherwise commercializing any Website material;
                    </li>
                    <li style={{fontSize:'1rem', fontFamily:'sans-serif'}}>
                    publicly performing and/or showing any Website material;
                    </li>
                    <li style={{fontSize:'1rem', fontFamily:'sans-serif'}}>
                    using this Website in any way that is or may be damaging to this Website;
                    </li>
                    <li style={{fontSize:'1rem', fontFamily:'sans-serif'}}>
                    using this Website in any way that impacts user access to this Website;
                    </li>
                    <li style={{fontSize:'1rem', fontFamily:'sans-serif'}}>
                    using this Website contrary to applicable laws and regulations, or in any way 
                    may cause harm to the Website, or to any person or business entity;
                    </li>
                    <li style={{fontSize:'1rem', fontFamily:'sans-serif'}}>
                    engaging in any data mining, data harvesting, data extracting or any other 
                    similar activity in relation to this Website;
                    </li>
                    <li style={{fontSize:'1rem', fontFamily:'sans-serif'}}>
                    using this Website to engage in any advertising or marketing.
                    </li>
                    </ul>
                    
                </Box>
                <Typography fontSize={'1rem'}>
                Certain areas of this Website are restricted from being access by you and Intopz 
                Technologies Pvt Ltd may further restrict access by you to any areas of this Website, 
                at any time, in absolute discretion. Any user ID and password you may have for this 
                Website are confidential and you must maintain confidentiality as well.
                </Typography>
                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Your Content
                </Typography>
                <Typography fontSize={'1rem'}>
                In these Website Standard Terms and Conditions, "Your Content" shall mean any audio, 
                video text, images or other material you choose to display on this Website. 
                By displaying Your Content, you grant Intopz Technologies Pvt Ltd a non-exclusive, 
                worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish, 
                translate and distribute it in any and all media.
                </Typography>
                <Typography mt={1} fontSize={'1rem'}>
                Your Content must be your own and must not be invading any third-party's rights. 
                Intopz Technologies Pvt Ltd reserves the right to remove any of Your Content from 
                this Website at any time without notice.
                </Typography>
                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Your Privacy
                </Typography>
                <Typography fontSize={'1rem'}>
                Please read Privacy Policy
                </Typography>
                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                No warranties
                </Typography>
                <Typography fontSize={'1rem'}>
                This Website is provided "as is," with all faults, and Intopz Technologies Pvt Ltd 
                express no representations or warranties, of any kind related to this Website or 
                the materials contained on this Website. Also, nothing contained on this Website 
                shall be interpreted as advising you.
                </Typography>
                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Limitation of liability
                </Typography>
                <Typography fontSize={'1rem'}>
                In no event shall Intopz Technologies Pvt Ltd , nor any of its officers, directors 
                and employees, shall be held liable for anything arising out of or in any way 
                connected with your use of this Website whether such liability is under contract. 
                Intopz Technologies Pvt Ltd , including its officers, directors and employees shall 
                not be held liable for any indirect, consequential or special liability arising out 
                of or in any way related to your use of this Website.
                </Typography>
                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Indemnification
                </Typography>
                <Typography fontSize={'1rem'}>
                You hereby indemnify to the fullest extent Intopz Technologies Pvt Ltd from and against 
                any and/or all liabilities, costs, demands, causes of action, damages and expenses 
                arising in any way related to your breach of any of the provisions of these Terms.
                </Typography>
                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Severability
                </Typography>
                <Typography fontSize={'1rem'}>
                If any provision of these Terms is found to be invalid under any applicable law, 
                such provisions shall be deleted without affecting the remaining provisions herein.
                </Typography>
                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Variation of Terms
                </Typography>
                <Typography fontSize={'1rem'}>
                Intopz Technologies Pvt Ltd is permitted to revise these Terms at any time as it sees fit, 
                and by using this Website you are expected to review these Terms on a regular basis.
                </Typography>
                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Assignment
                </Typography>
                <Typography fontSize={'1rem'}>
                The Intopz Technologies Pvt Ltd is allowed to assign, transfer, and subcontract its 
                rights and/or obligations under these Terms without any notification. However, you are 
                not allowed to assign, transfer, or subcontract any of your rights and/or obligations 
                under these Terms.
                </Typography>
                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Entire Agreement
                </Typography>
                <Typography fontSize={'1rem'}>
                These Terms constitute the entire agreement between Intopz Technologies Pvt Ltd and you 
                in relation to your use of this Website, and supersede all prior agreements and 
                understandings.
                </Typography>
                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Governing Law & Jurisdiction
                </Typography>
                <Typography fontSize={'1rem'}>
                These Terms will be governed by and interpreted in accordance with the laws of the 
                State of in, and you submit to the non-exclusive jurisdiction of the state and federal 
                courts located in for the resolution of any disputes.
                </Typography>
                </Box>


                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Shipping and Delivery:
                </Typography>
                <Typography fontSize={'1rem'}>
                We will deliver the login instructions for SmartOpz Products to your 
                account or through other reasonable means no later than when we have received payment of the applicable fees. 
                </Typography>
                <Typography mt={1} fontSize={'1rem'}>
                You are responsible for accessing your account to determine that we 
                have received payment and that your Order has been processed. All deliveries under these Terms will be electronic                    
                </Typography>

                <Typography mt={1} fontSize={'1rem'}>
                Our Deliverables. We will retain all right, title and interest in and 
                to Our Deliverables. You may use any of Our Deliverables provided to you 
                only in connection with the cloud Products, subject to the same usage 
                rights and restrictions as for the Cloud Products. For clarity, Our 
                Deliverables are not considered Cloud Products, and any Cloud Products 
                are not considered to be Our Deliverables.                    
                </Typography>
                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Refund and Cancellation policy: 
                </Typography>
                <Typography fontSize={'1rem'}>
                Regardless of the billing cycle, we provide no refunds, no right of set-off, or credits for any unused time of the Services for whatever reason prior to the end of the Subscription Period (as defined below). breach of this Agreement and/or applicable law.
                </Typography>
                <Typography mt={1} fontSize={'1rem'}>
                There will be no exceptions in order to treat all Clients equally and to keep our administrative costs low for the ultimate benefit of our Clients.                    
                </Typography>
                </Box>


                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Subscription availability details are as follows:
                </Typography>
                <Typography mt={2} variant='h6' fontWeight={'bold'}>
                Trial Services
                </Typography>

                <Box mt={2}>
                <ol type="a"> 
                    <li style={{fontSize:'1rem', fontFamily:'sans-serif'}}>After signing up, the Client gets access to a new, fully-functional
                         trial Account without charge and valid for some period (depending on Services)
                          to get to know Services well before subscribing to Paid Subscription. 
                          Trial Services are subject to the Agreement. During a trial, a credit 
                          card is not required.
                    </li> 
                    <li style={{fontSize:'1rem', marginTop:'1rem', fontFamily:'sans-serif'}}>
                    Trial Services are provided on an “as-is” and “as - available” basis without any warranty of 
                    any kind not expressly stated therein, and the Company does not represent or warrant to the 
                    Client that: (1) Client’s use of the Trial Services will meet Client's requirements, (2) 
                    Client’s use of the Trial Services will be uninterrupted, accurate, secure, accessible or free from error.
                    </li> 
                    <li style={{fontSize:'1rem', marginTop:'1rem', fontFamily:'sans-serif'}}>
                    The Client agrees that the Company will not be liable to the Client or any 
                    third party arising out of, or in connection with Trial Services. In particular, 
                    the Company shall have no indemnification obligations, nor liability of any type, 
                    with respect to the Trial Services unless such exclusion of liability is not 
                    enforceable under applicable law.
                    </li>
                    <li style={{fontSize:'1rem', marginTop:'1rem', fontFamily:'sans-serif'}}>
                    Notwithstanding anything to the contrary in points 1. and 2. above, the Client 
                    shall be fully liable to the Company for any damages arising out of Client's use 
                    of the Trial Services, any breach by Client of the Agreement, and any of Client's 
                    indemnification obligations hereunder.
                    </li>
                </ol>
                </Box>

                <Typography mt={2} variant='h6' fontWeight={'bold'}>
                Paid Services
                </Typography>

                <Box mt={2}>
                <ol type="a"> 
                    <li style={{fontSize:'1rem', fontFamily:'sans-serif'}}>
                    Upon the end of Trial Services, if the Client wishes to continue using the Services,
                     the Client will be requested to provide payment details to make a subscription fee 
                     according to the currently effective rates placed on the applicable Sites. The 
                     Client is responsible for all charges in its Account. We do not offer refunds and/or 
                     pro-rata refunds on already processed payments and this policy is the same for all 
                     Clients.
                    </li> 
                    <li style={{fontSize:'1rem', marginTop:'1rem', fontFamily:'sans-serif'}}>
                    The Agreement shall be concluded for the term specified by the Client or as defined in 
                    an applicable Order Form (“Subscription Period”). The term of the Agreement is 
                    automatically extended for subsequent periods equal to the expiring Subscription 
                    Period unless otherwise agreed or the Account has been closed.
                    </li> 
                    <li style={{fontSize:'1rem', marginTop:'1rem', fontFamily:'sans-serif'}}>
                    Paid services may be discontinued at any time: i. by you by closing your Account. 
                    Closing the Account means that further use of the Services using the existing 
                    password and login will no longer be possible, and after closing the Client Account, 
                    it will cease to work immediately. ii. we can close or suspend the Paid Services or 
                    any part or function thereof, without prior notice to you, due to a breach of any 
                    provision of this Agreement, applicable law, as well as faulty or unlawful use of 
                    the Services by the Client, its Agents or End Users. 
                    </li>
                </ol>
                </Box>               
                </Box>
            </Box>


        </Box>
    )
}

export default TermsAndConditionsPage;