import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Logo from '../Assests/SmartOpz_Logo.jpg';
import { useNavigate } from "react-router-dom";


const RefundPolicyPage =()=>{

    const navigate = useNavigate();

    const GooglePolicyLink = () =>{
        const googleLink = 'https://policies.google.com/technologies/ads';
        window.open(googleLink, '_blank');
    }

    useEffect(() => {
        // Scroll to the top of the page when component mounts
        window.scrollTo(0, 0);
    }, []);

    



    return(
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <AppBar position="fixed" id='AppBar' width={200} >
                <Toolbar  sx={{ backgroundColor: 'white', color: 'black', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                    <Box display={'flex'} mr={5}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{ mr: 2 , 
                                '&:hover': {
                                    backgroundColor:'white'
                                }, }}
                        >
                            <img src={Logo} alt='Logo' 
                            width={190} height={50}
                             onClick={()=>{
                                navigate('/')
                             }}
                              />
                        </IconButton>
                    </Box>
                        <Box justifyContent={'center'} display={'flex'} flex={0.8}>
                            <Typography
                                variant="h3"
                                sx={{fontSize:{
                                    xs:'2rem',
                                    lg:'3rem'
                                }}}
                            >
                                Refund Policy
                            </Typography>
                           
                        </Box>
                </Toolbar>
            </AppBar>

            <Box sx={{ overflowX: 'auto', mt: 12, width: '75%' }}>

                <Typography variant='h4' fontWeight={'bold'} >
                We follow a reliable refund policy to let our customers feel privileged about their association with us. Please read the guidelines governing the refund policy.
                </Typography>

                <Box>
                <ol>
                    <li start='1' style={{fontSize:'1rem', fontFamily:'sans-serif'}} >
                    If you wish to cancel your account, notify us at least 15 days 
                    before end of your term (via an email to support@pushassist.com) . 
                    Upon cancellation, your data is deleted from our servers. Since 
                    deletion of all data is final please be sure that you do in fact 
                    want to cancel your account before doing so.
                    </li>

                    <li style={{fontSize:'1rem', fontFamily:'sans-serif',marginTop:3}} >
                    Your subscription gets renewed automatically at the end of each month
                    </li>

                    <li style={{fontSize:'1rem', fontFamily:'sans-serif',marginTop:3}} >
                    You can upgrade or downgrade level of our services at any time of your usage, however PushAssist does not refund any fees in that case
                    </li>

                    <li style={{fontSize:'1rem', fontFamily:'sans-serif',marginTop:3}} >
                    If you stop using our services in between the term, we will not refund you the fees paid by you for the remaining term
                    </li>

                    <li style={{fontSize:'1rem', fontFamily:'sans-serif',marginTop:3}} >
                    We reserve the right to modify or terminate the PushAssist service(s) for any reason, without notice at any time.
                    </li>

                    <li style={{fontSize:'1rem', fontFamily:'sans-serif',marginTop:3}} >
                    <Typography fontSize='1rem' fontWeight={'bold'} component={'span'}>Fraud: </Typography> Without limiting any other remedies, PushAssist may suspend or terminate your 
                    account if we suspect that you (by conviction, settlement, insurance or investigation, 
                    or otherwise, forcefully removed our branding) have engaged in fraudulent activity in 
                    connection with the services offered by us.
                    </li>

                    <li style={{fontSize:'1rem', fontFamily:'sans-serif',marginTop:3}} >
                    <Typography fontSize='1rem' fontWeight={'bold'} component={'span'}>Note:</Typography> 
                    No refunds are offered, even if a plan is canceled mid-month.
                    </li>
                    
                </ol>                 
                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                15 Day No Obligation Cancellation
                </Typography>
                <Typography mt={2}>
                PushAssist offers SaaS service to companies under a 15-day no obligation cancellation policy. 
                The no obligation cancellation begins immediately upon account activation. The 15-day no 
                obligation cancellation period only applies to new customers and does not apply to existing 
                customers that have a subscription plan. PushAssist will also not honor a free trial period 
                for customers who were once paying subscribers, cancelled, and then decided to re-instate 
                their subscription to PushAssist.
                </Typography>
                <Typography mt={2}>
                At any time during the 15-day, potential subscriber (customer) can cancel their account and will be refunded in full with a minor service charge.
                </Typography>

                <Typography mt={2}>
                In order to continue using SaaS after the 15-day no obligation cancellation period, 
                you will be automatically be put to continue to your selected payment plan which you 
                have selected initially at time of buying from range of selections of monthly or receiving 
                a discount by paying for half yearly or full year of service.                    
                </Typography>
                </Box>

                <Box mt={3}>
                <Typography  variant='h5' fontWeight={'bold'}>
                Monthly Billing, Cancellation, and Refund Policies
                </Typography>
                <Typography mt={2} >
                PushAssist charges and collects in advance for use of the SaaS service. All services rendered are non-refundable. Once a customer selects a subscription plan and provides billing information, PushAssist will calculate a pro-rated amount with the first payment due on signing.
                </Typography>
                <Typography mt={2}>
                All subscriptions monthly, quarterly and yearly renew automatically on their due renewal date according to date of purchase until officially cancelled in writing. Customers may cancel anytime by emailing a notice to: support@pushassist.com
                Customers will receive an email from PushAssist confirming that their subscription to the SaaS service has been cancelled.
                    </Typography>
                    <Typography fontWeight={'bold'} mt={2}>
                    Important:
                    <Typography component={'span'}>
                    No refunds or credits for partial months, quarters or years of service will be refunded to a customer upon cancellation.
                    All fees are exclusive of all taxes, levies, or duties imposed by taxing authorities, and the customer is responsible for payments of all such taxes, levies, or duties.
                    </Typography>

                    </Typography>

                    <Typography mt={2}>
                    The fees that PushAssist charges for the monthly, quarterly, or yearly services 
                    exclude phone, and Internet access charges, as well as other data transmission charges. 
                    Any currency exchange settlements are based on the customer's agreement with the payment 
                    method provider. To be clear: these charges are the customer's responsibility.                    
                    </Typography>

                    <Typography mt={2}>
                    Customers have the ability to upgrade or downgrade their subscription plan for the SaaS 
                    services at any time. For any kind of upgrade or downgrade in subscription, customer has 
                    to write to support@pushassist.com.
                    The chosen method of billing will automatically be charged the new subscription rate on the 
                    next billing cycle.                        
                    </Typography>

                    <Typography mt={2}>
                    With regards to downgrades on a subscription, PushAssist does not issue refunds or credits 
                    for partial months of service.                        
                    </Typography>


                    <Typography mt={2}>
                    PushAssist reserves the right to refuse/cancel a subscription to any of the SaaS services 
                    bought from pushassist.com, If PushAssist refuses a new or renewing subscription/membership, 
                    registrants will be offered a refund on prorata basis of pending days in subscription plan 
                    bought with an additional cancellation charge of $4.99.                        
                    </Typography>

                    <Typography mt={2}>
                    These above policies apply to all the SaaS services listed on PushAssist unless otherwise 
                    noted in the corresponding program materials.
                    </Typography>
                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Refund:
                </Typography>
                <Typography mt={2}>
                Payment obligations are non-cancelable, regardless of utilization by the Customer and except as expressly permitted in this Agreement, Fees paid are non-refundable.                
                </Typography>
                <Typography mt={2}>
                Customer will pay the Fees through an accepted payment method as specified in the applicable SOF or Website. Unless otherwise set forth in the SOF, 
                </Typography>

                <Typography mt={2}>
                Customer’s subscription to the Services will renew automatically for a Subscription Term 
                in accordance with the renewal terms and conditions set forth in Section 6(b) below. 
                During the Term, the Customer shall not reduce their Service Plan or User count.
                </Typography>
                <Typography mt={2}>
                    <u>Renewals.</u>  
                    You will pay all fees in accordance with each Order, by the due dates 
                    and in the currency specified in the Order. If a PO number is required 
                    in order for an invoice to be paid, then you must provide such PO number 
                    to Atlassian by emailing the PO number to sales@atlassian.com. For Additional 
                    Services provided at any non-Atlassian location, unless otherwise specified in 
                    your Order, you will reimburse us for our pre-approved travel, lodging and meal 
                    expenses, which we may charge as incurred. Other than as expressly set forth in 
                    Section 10 (Our return policy), 18.3 (Warranty Remedy), Section 20 (IP Indemnification) 
                    or Section 24 (Changes to these Terms), all amounts are non-refundable, non-cancelable 
                    and non-creditable. You agree that we may bill your credit card or other payment method 
                    for renewals, additional users, overages to set limits or scopes of use, expenses, and 
                    unpaid fees, as applicable.
                    
                </Typography>

                <Typography mt={2}>
                    <u>Payment.</u>  Except as otherwise specified in your Order, unless either party 
                    cancels your subscription prior to expiration of the current Subscription Term, your 
                    subscription will automatically renew for another Subscription Term of a period equal 
                    to your initial Subscription Term. You will provide any notice of non-renewal through 
                    the means we designate, which may include account settings in the Cloud Products or 
                    contacting our support team. Cancelling your subscription means that you will not be 
                    charged for the next billing cycle, but you will not receive any refunds or credits 
                    for amounts that have already been charged. All renewals are subject to the applicable 
                    Cloud Product continuing to be offered and will be charged at the then-current rates.
                    
                </Typography>
                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Refund and Cancellation policy: 
                </Typography>
                <Typography mt={2} >
                Regardless of the billing cycle, we provide no refunds, no right of set-off, 
                or credits for any unused time of the Services for whatever reason prior to the 
                end of the Subscription Period (as defined below). breach of this Agreement 
                and/or applicable law.
                </Typography>
                <Typography mt={2}>
                There will be no exceptions in order to treat all Clients equally and to keep 
                our administrative costs low for the ultimate benefit of our Clients.
                </Typography>
                <Typography mt={2}>
                1. Subscription availability details are as follows:
                </Typography>
                <Typography mt={2}>
                Trial Services
                </Typography>
                <ol type="a" style={{marginTop:3}}>
                    <li style={{fontSize:'1rem', fontFamily:'sans-serif'}}>
                    After signing up, the Client gets access to a new, fully-functional trial Account without charge and valid for some period (depending on Services) to get to know Services well before subscribing to Paid Subscription. Trial Services are subject to the Agreement. During a trial, a credit card is not required.
                    </li>

                    <li style={{fontSize:'1rem', fontFamily:'sans-serif',marginTop:3}}>
                    Trial Services are provided on an “as-is” and “as - available” basis without any warranty of any kind not expressly stated therein, and the Company does not represent or warrant to the Client that: (1) Client’s use of the Trial Services will meet Client’s requirements, (2) Client’s use of the Trial Services will be uninterrupted, accurate, secure, accessible or free from error.
                    </li>

                    <li style={{fontSize:'1rem', fontFamily:'sans-serif',marginTop:3}}>
                    The Client agrees that the Company will not be liable to the Client or any third party arising out of, or in connection with Trial Services. In particular, the Company shall have no indemnification obligations, nor liability of any type, with respect to the Trial Services unless such exclusion of liability is not enforceable under applicable law.
                    </li>

                    <li style={{fontSize:'1rem', fontFamily:'sans-serif',marginTop:3}}>
                    Notwithstanding anything to the contrary in points 1. and 2. above, the Client shall be fully liable to the Company for any damages arising out of Client’s use of the Trial Services, any breach by Client of the Agreement, and any of Client’s indemnification obligations hereunder.
                    </li>
                    
                </ol>

                <Typography mt={2}>
                Paid Services
                </Typography>
                <ol type="a" style={{marginTop:3}}>
                    <li style={{fontSize:'1rem', fontFamily:'sans-serif'}}>
                    Upon the end of Trial Services, if the Client wishes to continue using the Services, the Client will be requested to provide payment details to make a subscription fee according to the currently effective rates placed on the applicable Sites. The Client is responsible for all charges in its Account. We do not offer refunds and/or pro-rata refunds on already processed payments and this policy is the same for all Clients.
                    </li>

                    <li style={{fontSize:'1rem', fontFamily:'sans-serif'}}>
                    The Agreement shall be concluded for the term specified by the Client or as defined in an applicable Order Form (“Subscription Period”). The term of the Agreement is automatically extended for subsequent periods equal to the expiring Subscription Period unless otherwise agreed or the Account has been closed.
                    </li>

                    <li style={{fontSize:'1rem', fontFamily:'sans-serif'}}>
                    Paid services may be discontinued at any time: i. by you by closing your Account. Closing the Account means that further use of the Services using the existing password and login will no longer be possible, and after closing the Client Account, it will cease to work immediately. ii. we can close or suspend the Paid Services or any part or function thereof, without prior notice to you, due to a breach of any provision of this Agreement, applicable law, as well as faulty or unlawful use of the Services by the Client, its Agents or End Users. None of the above methods of closing the Account or its suspension before the end of the Subscription Period for which the payment was made shall oblige LiveChat to return the amount for the unused period.
                    </li>

                    <li style={{fontSize:'1rem', fontFamily:'sans-serif'}}>

                    </li>
                    
                </ol>

                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Shipping and Delivery:
                </Typography>
                <Typography mt={2}>
                We will deliver the login instructions for SmartOpz Products to your account or through other reasonable means no later than when we have received payment of the applicable fees.
                </Typography>
                <Typography mt={2}>
                You are responsible for accessing your account to determine that we have received payment and that your Order has been processed. All deliveries under these Terms will be electronic
                </Typography>
                <Typography mt={2}>
                Our Deliverables. We will retain all right, title and interest in and to Our Deliverables. You may use any of Our Deliverables provided to you only in connection with the cloud Products, subject to the same usage rights and restrictions as for the Cloud Products. For clarity, Our Deliverables are not considered Cloud Products, and any Cloud Products are not considered to be Our Deliverables.
                </Typography>
                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Refund and Cancellation policy:
                </Typography>
                <Typography mt={2}>
                Regardless of the billing cycle, we provide no refunds, no right of set-off, or credits for any unused time of the Services for whatever reason prior to the end of the Subscription Period (as defined below). breach of this Agreement and/or applicable law.
                </Typography>
                <Typography mt={2}>
                There will be no exceptions in order to treat all Clients equally and to keep our administrative costs low for the ultimate benefit of our Clients.
                </Typography>
                <Typography mt={2}>
                1. Subscription availability details are as follows:
                </Typography>
                <Typography mt={2}>
                Trial Services
                </Typography>
                <ol type="a" style={{marginTop:3}}>
                    <li style={{fontSize:'1rem', fontFamily:'sans-serif'}}>
                    After signing up, the Client gets access to a new, fully-functional trial Account 
                    without charge and valid for some period (depending on Services) to get to know Services 
                    well before subscribing to Paid Subscription. Trial Services are subject to the Agreement. 
                    During a trial, a credit card is not required.
                    </li>

                    <li style={{fontSize:'1rem', fontFamily:'sans-serif'}}>
                    Trial Services are provided on an “as-is” and “as - available” basis without any warranty 
                    of any kind not expressly stated therein, and the Company does not represent or warrant to 
                    the Client that: (1) Client’s use of the Trial Services will meet Client’s requirements, (2) 
                    Client’s use of the Trial Services will be uninterrupted, accurate, secure, accessible or 
                    free from error.
                    </li>

                    <li style={{fontSize:'1rem', fontFamily:'sans-serif'}}>
                    The Client agrees that the Company will not be liable to the Client or any third party 
                    arising out of, or in connection with Trial Services. In particular, the Company shall 
                    have no indemnification obligations, nor liability of any type, with respect to the Trial 
                    Services unless such exclusion of liability is not enforceable under applicable law.
                    </li>

                    <li style={{fontSize:'1rem', fontFamily:'sans-serif'}}>
                    Notwithstanding anything to the contrary in points 1. and 2. above, the Client shall be 
                    fully liable to the Company for any damages arising out of Client’s use of the Trial 
                    Services, any breach by Client of the Agreement, and any of Client’s indemnification 
                    obligations hereunder.
                    </li>
                    
                </ol>
                </Box>
            </Box>


        </Box>
    )
}

export default RefundPolicyPage;