import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./Home/Home";
import TermsAndConditionsPage from "./TermsAndConditions/TermsAndConditions";
import PrivacyPolicyPage from "./PrivacyPolicy/PrivacyPolicy";
import RefundPolicyPage from "./RefundPolicy/RefundPolicy";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomePage />}></Route>
        <Route exact path="/terms_and_conditions" element={<TermsAndConditionsPage />}></Route>
        <Route exact path="/privacy_policy" element={<PrivacyPolicyPage />}></Route>
        <Route exact path="/refund_policy" element={<RefundPolicyPage />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
