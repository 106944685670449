// Included by sanjana SOP-11
import React from 'react';
import { useState } from 'react'; // Import useState hook,

// SOP-47 Included By PCS 
import { useEffect } from 'react'; 


import {
    Box,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Divider,
    Button,
} from '@mui/material';
import { styled, alpha, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import './Pricing.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckedButton from '../Assests/check.png'
import CancelButton from '../Assests/cancel.png'
import Dialog from '@mui/material/Dialog';
import Autocomplete from '@mui/material/Autocomplete';
import SignUpBG from '../Assests/Wave1.png';
import PricingSignUpPage from './PricingSignUp';

//SOP-47 Included By PCS
 import axios from "axios";
//EOF SOP-47 

const PricingPage = () =>{

    const [signUpOpen, setSignUpOpen] = React.useState(false);
    const [ planValue, setPlanValue] =  useState('');

    const handleSignUpOpen = () => {
      setSignUpOpen(true);
    };
  
    const handleSignUpClose = () => {
      setSignUpOpen(false);
    };

//SOP-47 Included By PCS [To  display plan details in Pricing Card from sys_plan table]

    const [FreePlanDetails, setFreePlanDetails] =useState({})
    const [StandardPlanDetails, setStandardPlanDetails] =useState({})
    const [EnterprisePlanDetails, setEnterprisePlanDetails] =useState({})

 
  const GetPlanDetails = async () => 
    {
      
        try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/plan/getPlanDetails`);
                // Iterate through the response data
                console.log('response',response)

              for (let i = 0; i < response.data.data.length; i++)
                {
                    if (response.data.data[i].sys_planid === 1) {
                      setFreePlanDetails (response.data.data[i])
                        
                                                                }
                    if (response.data.data[i].sys_planid === 2) {
                        setStandardPlanDetails (response.data.data[i])
                        
                                                                }
                    if (response.data.data[i].sys_planid === 3) {
                        setEnterprisePlanDetails (response.data.data[i])
                                                                }

                }
            }
        catch (err)
            {
                console.log( "error",err);
            }
    };


    // Extracting and displaying the values

    const freeplanName = FreePlanDetails.sys_planname;
    const standardplanName = StandardPlanDetails.sys_planname;
    const enterpriseplanName = EnterprisePlanDetails.sys_planname;

    const freeplanDays = FreePlanDetails.allowednumberofdays;

    const freeplanAssetcount = FreePlanDetails.allowednumberofassets;
    const standardplanAssetcount = StandardPlanDetails.allowednumberofassets;
    const enterpriseplanAssetcount = EnterprisePlanDetails.allowednumberofassets;

    const freeplanPrice = FreePlanDetails.planprice;
    const standardplanPrice = StandardPlanDetails.planprice;
    const enterpriseplanPrice = EnterprisePlanDetails.planprice;


    useEffect(() => 
    {
    GetPlanDetails();
    }, []);


//EOF SOP-47 Included By PCS  

    const planTypeOptions =[
        { label: 'Free', value:'Free' },
        { label: 'Standard', value:'Standard'},
        { label: 'Enterprise', value:'Enterprise'},
       ]

    return(
        <Box 
        sx={{
            height:{ 
            lg:'110vh',
            xs:'260vh',
            md:'115vh',
            xl:'90vh'
        },
            width: '98vw',
            bgcolor:'#f8f8ff'
        }}
        >
            <Box display="flex" alignItems="center" justifyContent="center">
            <Typography mt={15}  variant='h2' fontWeight={'bold'} fontFamily={'Segoe UI'}
            sx={{color:'#4169e1',
                 fontSize:{
                    xs:'1.7rem',
                    md:'3rem'
                 }
        
            }}>
                {/* //SOP-75 feedback points L1 Modified by PCS [spelling correction] */}
                {/* Pricing - Chose your right Plan  */}
                Pricing - Choose your right Plan 
                {/* //EOF SOP-75 feedback points L1 Modified by PCS [spelling correction] */}
                </Typography >
            </Box>

            <Grid container mt={5}  display="flex" alignItems="center" justifyContent='center' >
               
                {/* Card 1 */}
                <Grid item lg={3.2} md={4} xs={12} display="flex" alignItems="center" justifyContent='center' >
                <Box border={'1px solid #5B51C4'} borderRadius={2}
                sx={{
                    height:{
                        //SOP-75 feedback points L1 modified by PCS [including docpoint in pricing-height of the card is modified]
                            // xs:400,
                            // lg:460,
                            // md:420

                            xs:425,
                            lg:490,
                            md:440
                       //EOF SOP-75 feedback points L1 modified by PCS [including docpoint in pricing-height of the card is modified]

                    },

                    // Changes done by TS
                       // width:{
                    //     xs:300,
                    //     lg:300,
                    //     md:300,
                    //     xl:350
                    // }
                    width:{
                        xs:300,
                        lg:350,
                        md:300,
                        xl:350
                    }
                }}>
                    <Box >
                        <Box  height={5} bgcolor={'#5B51C4'} borderRadius={'6px 6px 0 0'}>
                            
                        </Box>
                        <Box mt={2} display="flex" alignItems="center" justifyContent="center">
                        <Typography sx={{
                                fontSize:{
                                    xs:'1.5rem',
                                    lg:'2rem',
                                    md:'1.5rem'
                                 }
                            }} color={'#5B51C4'} fontFamily={'Segoe UI'}>
                               
                                {/* SOP-47  Modified By PCS */}
                                {/* Free */}
                                {freeplanName}
                                {/* EOF SOP-47 */}
                                
                            </Typography>

                        </Box>
                    <Typography fontFamily={'Segoe UI'} sx={{
                                fontSize:{
                                    xs:'2.5rem',
                                    lg:'2.5rem',
                                    md:'2.5rem'
                                 }
                            }}  display="flex" alignItems="center" justifyContent="center" mt={3} fontSize={'2.5rem'}>
                        
                        {/* SOP-47  Modified By PCS */}
                        {/* $0   */}
                        ${freeplanPrice}
                        {/* EOF SOP-47 */}
                    </Typography> 
                    <Typography sx={{
                                fontSize:{
                                    xs:'2rem',
                                    lg:'2rem',
                                    md:'2rem'
                                 }
                            }} fontFamily={'Segoe UI'}  display="flex" alignItems="center" justifyContent="center" fontSize={'2rem'} color={'darkgray'}>
                    
                   
                    {/* SOP-47  Modified By PCS */}
                    {/* 60 days trial  */}
                    {freeplanDays} days trial
                    {/* EOF SOP-47 */}
                    
                    </Typography>

                    <Typography fontFamily={'Segoe UI'} sx={{
                                fontSize:{
                                    xs:'1rem',
                                    lg:'1.3rem',
                                    md:'1'
                                 }
                            }} mt={3}  display="flex" alignItems="center" justifyContent="center" fontSize={'2rem'} color={'black'}>
                   
                    {/* SOP-47  Modified By PCS */}
                    {/*  100 units */}
                    {freeplanAssetcount} units
                    {/* EOF SOP-47 */}

                    </Typography>

                    <Typography fontFamily={'Segoe UI'} sx={{
                                fontSize:{
                                    xs:'1rem',
                                    lg:'1.3rem',
                                    md:'1'
                                 }
                            }}  display="flex" alignItems="center" justifyContent="center" fontSize={'2rem'} color={'black'}>
                    Asset Management 
                    </Typography>
                    <Typography fontFamily={'Segoe UI'} sx={{
                                fontSize:{
                                    xs:'1rem',
                                    lg:'1.3rem',
                                    md:'1'
                                 }
                            }}  display="flex" alignItems="center" justifyContent="center" fontSize={'2rem'} color={'black'}>
                    Ticketing System
                    </Typography>

                    <Typography fontFamily={'Segoe UI'} sx={{
                                fontSize:{
                                    xs:'1rem',
                                    lg:'1.3rem',
                                    md:'1'
                                 }
                            }}  display="flex" alignItems="center" justifyContent="center" fontSize={'2rem'} color={'black'}>
                    HR Management 
                    </Typography>
                    
                {/* //SOP-75 feedback points L1 included By PCS [including docpoint] */}
                    <Typography fontFamily={'Segoe UI'} sx={{
                                fontSize:{
                                    xs:'1rem',
                                    lg:'1.3rem',
                                    md:'1'
                                 }
                            }}  display="flex" alignItems="center" justifyContent="center" fontSize={'2rem'} color={'black'}>
                    DocPoint 
                    </Typography>
                {/* //EOF SOP-75 feedback points L1 included By PCS */}

                    <Box
                   
                        mt={5} 
                        display="flex" alignItems="center" justifyContent="center">
                        <Button  fontSize={'2.5rem'} fontFamily={'Segoe UI'}
                        sx={{background: 'white',
                        border: '1px solid #4169e1',
                        borderRadius: '5px',
                        padding:{ 
                            lg:"0 70px",
                        },
                        color: '#4169e1',
                        height:{ 
                            lg:"3rem",
                            xs:'3rem',
                            md:'3rem'
                        },
                        width:{
                            xs:'auto',
                            lg:'auto',
                            md:'auto'
                        },
                        "&:hover":{
                            color:'white',
                            backgroundColor:'#4169e1'
                        },
                        fontSize:{
                            xs:'1rem',
                            lg:'1rem',
                            md:'1rem'
                         }
                        }}
                        onClick={()=>{
                            handleSignUpOpen();
                            setPlanValue('Free')
                        }}
                        >
                            Try Now 
                        </Button>
                    </Box>                                             
                    </Box>
                </Box>
                </Grid>
                {/* End of Card 1 */}

                {/* Card 2 */}
                <Grid item lg={3.2} md={4} xs={12} 
                sx={{
                    mt:{
                        xs:3,
                        lg:0,
                        md:0
                    }
                }}
                display="flex" alignItems="center" justifyContent='center' >
                <Box border={'1px solid #5B51C4'} borderRadius={2}
                sx={{
                    height:{
                       
                        //SOP-75 feedback points L1 modified by PCS [including docpoint in pricing-height of the card is modified]
                        // xs:400,
                        // lg:460,
                        // md:420

                        xs:425,
                        lg:490,
                        md:440
                      //EOF SOP-75 feedback points L1 modified by PCS [including docpoint in pricing]
                    },

                    // Changes done by TS
                       // width:{
                    //     xs:300,
                    //     lg:300,
                    //     md:300,
                    //     xl:350
                    // }

                    width:{
                        xs:300,
                        lg:350,
                        md:300,
                        xl:350
                    }
                }}>
                    <Box >
                        <Box   height={5} bgcolor={'#5B51C4'} borderRadius={'6px 6px 0 0'}>
                            
                        </Box>
                        <Box mt={2} display="flex" alignItems="center" justifyContent="center">
                            <Typography fontFamily={'Segoe UI'} sx={{
                                fontSize:{
                                    xs:'1.5rem',
                                    lg:'2rem',
                                    md:'1.5rem'
                                 }
                            }} color={'#5B51C4'} fontSize={'1.5rem'}>
                                
                                {/* SOP-47  Modified By PCS */}
                                {/* Standard */}
                                {standardplanName}
                                {/* EOF SOP-47 */}
                            </Typography>

                        </Box>
                    <Typography fontFamily={'Segoe UI'} sx={{
                                fontSize:{
                                    xs:'2.5rem',
                                    lg:'2.5rem',
                                    md:'2.5rem'
                                 }
                            }}  display="flex" alignItems="center" justifyContent="center" mt={3} fontSize={'2.5rem'}>
                       
                        {/* SOP-47  Modified By PCS */}
                        {/* $1   */}
                        ${standardplanPrice}
                        {/* EOF SOP-47 */}
                    </Typography> 
                    <Typography fontFamily={'Segoe UI'} sx={{
                                fontSize:{
                                    xs:'2rem',
                                    lg:'2rem',
                                    md:'2rem'
                                 }
                            }}  display="flex" alignItems="center" justifyContent="center" fontSize={'2rem'} color={'darkgray'}>
                            per month 
                    </Typography>

                    <Typography fontFamily={'Segoe UI'} sx={{
                                fontSize:{
                                    xs:'1rem',
                                    lg:'1.3rem',
                                    md:'1'
                                 }
                            }} mt={3}  display="flex" alignItems="center" justifyContent="center" fontSize={'2rem'} color={'black'}>
                    
               
                    {/* SOP-47  Modified By PCS */}
                    {/*  1000 units */}
                    {standardplanAssetcount} units
                    {/* EOF SOP-47 */}
                    </Typography>

                    <Typography fontFamily={'Segoe UI'} sx={{
                                fontSize:{
                                    xs:'1rem',
                                    lg:'1.3rem',
                                    md:'1'
                                 }
                            }}  display="flex" alignItems="center" justifyContent="center" fontSize={'2rem'} color={'black'}>
                    Asset Management 
                    </Typography>
                    <Typography fontFamily={'Segoe UI'} sx={{
                                fontSize:{
                                    xs:'1rem',
                                    lg:'1.3rem',
                                    md:'1'
                                 }
                            }}  display="flex" alignItems="center" justifyContent="center" fontSize={'2rem'} color={'black'}>
                    Ticketing System
                    </Typography>

                    <Typography fontFamily={'Segoe UI'} sx={{
                                fontSize:{
                                    xs:'1rem',
                                    lg:'1.3rem',
                                    md:'1'
                                 }
                            }}  display="flex" alignItems="center" justifyContent="center" fontSize={'2rem'} color={'black'}>
                    HR Management 
                    </Typography>

                           
                    {/* //SOP-75 feedback points L1 included By PCS */}
                    <Typography fontFamily={'Segoe UI'} sx={{
                                    fontSize:{
                                        xs:'1rem',
                                        lg:'1.3rem',
                                        md:'1'
                                    }
                                }}  display="flex" alignItems="center" justifyContent="center" fontSize={'2rem'} color={'black'}>
                        DocPoint 
                        </Typography>
                    {/* //EOF SOP-75 feedback points L1 included By PCS */}

                    <Box mt={5} display="flex" alignItems="center" justifyContent="center">
                        <Button  fontSize={'2.5rem'}
                        sx={{background: 'white',
                        border: '1px solid #4169e1',
                        borderRadius: '5px',
                        padding:{ 
                            lg:"0 70px",
                        },
                        color: '#4169e1',
                        height:{ 
                            lg:"3rem",
                            xs:'3rem',
                            md:'3rem'
                        },
                        width:{
                            xs:'auto',
                            lg:'auto',
                            md:'auto'
                        },
                        "&:hover":{
                            color:'white',
                            backgroundColor:'#4169e1'
                        },
                        fontSize:{
                            xs:'1rem',
                            lg:'1rem',
                            md:'1rem'
                         }
                        }}
                        onClick={()=>{
                            handleSignUpOpen();
                            setPlanValue('Standard')
                        }}
                        >
                            Buy Now 
                        </Button>
                    </Box>                                             
                    </Box>
                </Box>
                </Grid>
                {/* End of Card 2 */}

                {/* Card 3 */}
                <Grid item lg={3.2} md={4} xs={12}  sx={{
                    mt:{
                        xs:3,
                        lg:0,
                        md:0
                    }
                }} display="flex" alignItems="center" justifyContent='center' >
                <Box border={'1px solid #5B51C4'} borderRadius={2}
                sx={{
                    height:{
                    //SOP-75 feedback points L1 modified by PCS [including docpoint in pricing-height of the card is modified]
                        // xs:410,
                        // lg:460,
                        // md:420

                        xs:435,
                        lg:490,
                        md:440
                    //EOF SOP-75 feedback points L1 modified by PCS [including docpoint in pricing]

                        },

                // Changes done by TS
                    // width:{
                    //     xs:300,
                    //     lg:300,
                    //     md:300,
                    //     xl:350
                    // }
                    width:{
                        xs:300,
                        lg:350,
                        md:300,
                        xl:350
                    }
                }}>
                    
                    <Box >
                        <Box  height={5} bgcolor={'#5B51C4'} borderRadius={'6px 6px 0 0'}>
                            
                        </Box>
                        <Box mt={2} display="flex" alignItems="center" justifyContent="center">
                            <Typography fontFamily={'Segoe UI'} sx={{
                                    fontSize:{
                                        xs:'1.5rem',
                                        lg:'2rem',
                                        md:'1.5rem'
                                    }
                                }} color={'#5B51C4'} fontSize={'1.5rem'}>
                                    
                                {/* SOP-47  Modified By PCS */}
                                {/* Enterprise */}
                                {enterpriseplanName}
                                {/* EOF SOP-47 */}

                                </Typography>
                        </Box>
                    <Typography fontFamily={'Segoe UI'} sx={{
                                fontSize:{
                                    xs:'2.5rem',
                                    lg:'2.5rem',
                                    md:'2.5rem'
                                 }
                            }}  display="flex" alignItems="center" justifyContent="center" mt={3} fontSize={'2.5rem'}>
                        {/* SOP-47  Modified By PCS */}
                        {/* $3  */}
                        ${enterpriseplanPrice}
                        {/* EOF SOP-47 */}

                    </Typography> 
                    <Typography fontFamily={'Segoe UI'} sx={{
                                fontSize:{
                                    xs:'2rem',
                                    lg:'2rem',
                                    md:'2rem'
                                 }
                            }}  display="flex" alignItems="center" justifyContent="center" fontSize={'2rem'} color={'darkgray'}>
                    per month 
                    </Typography>

                    <Typography fontFamily={'Segoe UI'} sx={{
                                fontSize:{
                                    xs:'1rem',
                                    lg:'1.3rem',
                                    md:'1rem'
                                 }
                            }} mt={3}  display="flex" alignItems="center" justifyContent="center" fontSize={'2rem'} color={'black'}>
                     
                    {/* SOP-47  Modified By PCS */}
                    {/* Unlimited */}
                    {enterpriseplanAssetcount==0?'Unlimited':enterpriseplanAssetcount}
                    {/* EOF SOP-47 */}

                    </Typography>

                    <Typography fontFamily={'Segoe UI'} sx={{
                                fontSize:{
                                    xs:'1rem',
                                    lg:'1.3rem',
                                    md:'1'
                                 }
                            }}  display="flex" alignItems="center" justifyContent="center" fontSize={'2rem'} color={'black'}>
                    Asset Management 
                    </Typography>
                    <Typography fontFamily={'Segoe UI'} sx={{
                                fontSize:{
                                    xs:'1rem',
                                    lg:'1.3rem',
                                    md:'1'
                                 }
                            }}  display="flex" alignItems="center" justifyContent="center" fontSize={'2rem'} color={'black'}>
                    Ticketing System
                    </Typography>

                    <Typography fontFamily={'Segoe UI'} sx={{
                                fontSize:{
                                    xs:'1rem',
                                    lg:'1.3rem',
                                    md:'1rem'
                                 }
                            }}  display="flex" alignItems="center" justifyContent="center" fontSize={'2rem'} color={'black'}>
                    HR Management 
                    </Typography>

                           
                    {/* //SOP-75 feedback points L1 included By PCS [including docpoint] */}
                    <Typography fontFamily={'Segoe UI'} sx={{
                                    fontSize:{
                                        xs:'1rem',
                                        lg:'1.3rem',
                                        md:'1'
                                    }
                                }}  display="flex" alignItems="center" justifyContent="center" fontSize={'2rem'} color={'black'}>
                        DocPoint 
                        </Typography>
                    {/* //EOF SOP-75 feedback points L1 included By PCS */}

                    <Typography fontFamily={'Segoe UI'} sx={{
                                fontSize:{
                                    xs:'1.3rem',
                                    lg:'1.3rem',
                                    md:'1rem'
                                 }
                            }}  display="flex" alignItems="center" justifyContent="center" fontSize={'2rem'} color={'black'}>
                    Custom Flow
                    </Typography>

                    <Box mt={2} display="flex" alignItems="center" justifyContent="center">
                        <Button  fontSize={'2.5rem'}
                        sx={{background: 'white',
                        border: '1px solid #4169e1',
                        borderRadius: '5px',
                        padding:{ 
                            lg:"0 70px",
                        },
                        color: '#4169e1',
                        height:{ 
                            lg:"3rem",
                            xs:'3rem',
                            md:'3rem'
                        },
                        width:{
                            xs:'auto',
                            lg:'auto',
                            md:'auto'
                        },
                        "&:hover":{
                            color:'white',
                            backgroundColor:'#4169e1'
                        },
                        fontSize:{
                            xs:'1rem',
                            lg:'1rem',
                            md:'1rem'
                         }
                        }}
                        onClick={()=>{
                            handleSignUpOpen();
                            setPlanValue('Enterprise')
                        }}
                        >
                            Buy Now 
                        </Button>
                    </Box>                                             
                    </Box>
                </Box>
                </Grid>
                {/* End of Card 3 */}
            </Grid>

            <Box>
            <Dialog  open={signUpOpen} fullWidth >
                    <Box  height={70} bgcolor={'#4169e1'}>
                        <Box  display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <Typography fontFamily={'Segoe UI'} fontSize={'1.5rem'} color={'white'}>
                                SIGN UP
                            </Typography>
                        </Box>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <Typography fontFamily={'Segoe UI'} fontSize={'1.3rem'} color={'white'}>
                                Selected plan: {planValue}
                            </Typography>
                        </Box>
                    </Box>

                <PricingSignUpPage handleSignUpClose={handleSignUpClose} planValue={planValue} />

            </Dialog>

        </Box>
        </Box>
    )  

}

export default PricingPage

// End of SOP-11