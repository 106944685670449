const axios = require("axios");

const getServices = (url) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(url)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

const postService = (url, body, headers) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(url, body, { headers })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

export const commonService = {
    postService,
    getServices,
  };