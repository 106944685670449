import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});


// // Included by sanjana SOP-NEW-51
// // Custom CORS middleware setup
// App.use(function (req, res, next) {
//   res.setHeader("Access-Control-Allow-Origin", "http://43.205.136.197:5000/api/v1"); // Replace with your domain
//   res.setHeader("Access-Control-Allow-Methods", "GET, POST");
//   res.setHeader("Access-Control-Allow-Headers", "Content-Type");
//   next();
// });
// // End of SOP-NEW-51
root.render(
  <ThemeProvider theme={theme}>
    <ToastContainer autoClose={2000} />
    <App />
  </ThemeProvider>
);
