import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Logo from '../Assests/SmartOpz_Logo.jpg';
import { useNavigate } from "react-router-dom";


const PrivacyPolicyPage =()=>{

    const navigate = useNavigate();

    const GooglePolicyLink = () =>{
        const googleLink = 'https://policies.google.com/technologies/ads';
        window.open(googleLink, '_blank');
    }

    useEffect(() => {
        // Scroll to the top of the page when component mounts
        window.scrollTo(0, 0);
    }, []);

    
    return(
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <AppBar position="fixed" id='AppBar' width={200} >
                <Toolbar  sx={{ backgroundColor: 'white', color: 'black', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                    <Box display={'flex'} mr={5}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{ mr: 2 , 
                                '&:hover': {
                                    backgroundColor:'white'
                                }, }}
                        >
                            <img src={Logo} alt='Logo' 
                            width={190} height={50}
                             onClick={()=>{
                                navigate('/')
                             }}
                              />
                        </IconButton>
                    </Box>
                        <Box justifyContent={'center'} display={'flex'} flex={0.8}>
                            <Typography
                                variant="h3"
                                sx={{fontSize:{
                                    xs:'2rem',
                                    lg:'3rem'
                                }}}
                            >
                                Privacy Policy
                            </Typography>
                           
                        </Box>
                </Toolbar>
            </AppBar>

            <Box sx={{ overflowX: 'auto', mt: 12, width: '75%' }}>

                <Typography variant='h4' fontWeight={'bold'} >
                Privacy Policy for Intopz Technologies Pvt Ltd
                </Typography>

                <Typography mt={2}>
                At smartopz, accessible from www.smartopz.com, one of our main priorities is the 
                privacy of our visitors. This Privacy Policy document contains types of information 
                that is collected and recorded by smartopz and how we use it.
                </Typography>

                <Typography mt={2}>
                If you have additional questions or require more information about our Privacy Policy, 
                do not hesitate to contact us. Our Privacy Policy was created with the help of the Privacy 
                Policy Generator.
                </Typography>

                <Box>
                <Typography mt={3} variant='h5' fontWeight={'bold'}>
                Log Files
                </Typography>

                <Typography mt={2}>
                smartopz follows a standard procedure of using log files. 
                These files log visitors when they visit websites. All 
                hosting companies do this and a part of hosting services' analytics. 
                The information collected by log files include internet protocol (IP) 
                addresses, browser type, Internet Service Provider (ISP), date and time 
                stamp, referring/exit pages, and possibly the number of clicks. These 
                are not linked to any information that is personally identifiable. The 
                purpose of the information is for analyzing trends, administering the site, 
                tracking users' movement on the website, and gathering demographic information.
                </Typography>                 
                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Cookies and Web Beacons
                </Typography>
                <Typography mt={2}>
                Like any other website, smartopz uses 'cookies'. These cookies are used 
                to store information including visitors' preferences, and the pages on the 
                website that the visitor accessed or visited. The information is used to 
                optimize the users' experience by customizing our web page content based 
                on visitors' browser type and/or other information.
                </Typography>
                </Box>

                <Box mt={3}>
                <Typography  variant='h5' fontWeight={'bold'}>
                Google DoubleClick DART Cookie
                </Typography>
                <Typography mt={2} >
                Google is one of a third-party vendor on our site. It also uses cookies, 
                known as DART cookies, to serve ads to our site visitors based upon their 
                visit to www.website.com and other sites on the internet. However, 
                visitors may choose to decline the use of DART cookies by visiting the 
                Google ad and content network Privacy Policy at the following 
                URL - <Typography onClick={()=>{GooglePolicyLink()
                }} color={'#4169e1'} sx={{cursor:'pointer'}} component="span">
                    https://policies.google.com/technologies/ads
                    </Typography>
                </Typography>
                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Our Advertising Partners
                </Typography>
                <Typography>
                Some of advertisers on our site may use cookies and web beacons. 
                Our advertising partners are listed below. Each of our advertising 
                partners has their own Privacy Policy for their policies on user data. 
                For easier access, we hyperlinked to their Privacy Policies below.
                </Typography>
                <ul>
                    <li style={{fontSize:'1.2rem', fontFamily:'sans-serif'}}>
                        Google
                        <Typography>
                            <Typography onClick={()=>{GooglePolicyLink()
                }} color={'#4169e1'} sx={{cursor:'pointer'}}>
                    https://policies.google.com/technologies/ads
                    </Typography>
                        </Typography>
                    </li>
                    
                    </ul>
                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Privacy Policies
                </Typography>
                <Typography mt={2}>
                You may consult this list to find the Privacy Policy for each of the advertising partners of smartopz.
                </Typography>
                <Typography mt={2}>
                Third-party ad servers or ad networks uses technologies like cookies, 
                JavaScript, or Web Beacons that are used in their respective advertisements 
                and links that appear on smartopz, which are sent directly to users' browser. 
                They automatically receive your IP address when this occurs. These technologies 
                are used to measure the effectiveness of their advertising campaigns and/or to 
                personalize the advertising content that you see on websites that you visit.
                </Typography>
                <Typography mt={2}>
                Note that smartopz has no access to or control over these cookies that are used by third-party advertisers.
                </Typography>
                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Third Party Privacy Policies
                </Typography>
                <Typography mt={2}>
                smartopz's Privacy Policy does not apply to other advertisers or websites. 
                Thus, we are advising you to consult the respective Privacy Policies of these 
                third-party ad servers for more detailed information. It may include their 
                practices and instructions about how to opt-out of certain options.
                </Typography>
                <Typography mt={2}>
                You can choose to disable cookies through your individual browser options. 
                To know more detailed information about cookie management with specific web 
                browsers, it can be found at the browsers' respective websites. What Are Cookies?
                </Typography>
                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Children's Information
                </Typography>
                <Typography mt={2}>
                Another part of our priority is adding protection for children while using 
                the internet. We encourage parents and guardians to observe, participate in, 
                and/or monitor and guide their online activity.
                </Typography>
                <Typography mt={2}>
                smartopz does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
                </Typography>
                </Box>


                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Online Privacy Policy Only
                </Typography>
                <Typography>
                This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in smartopz. This policy is not applicable to any information collected offline or via channels other than this website.
                </Typography>
                </Box>

                <Box mt={3}>
                <Typography variant='h5' fontWeight={'bold'}>
                Consent
                </Typography>
                <Typography>
                By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.
                </Typography>
                </Box>
            </Box>


        </Box>
    )
}

export default PrivacyPolicyPage;