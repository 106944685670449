import React from 'react';
import { useState } from 'react'; // Import useState hook
import {
    Box,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Divider,
    Button,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import './Footer.css'
import { useNavigate } from "react-router-dom";

const Footer = () => {


    const navigate = useNavigate();

    const handleSmartopzButtonClick = () => {
        // Modified by sanjana SOP-41 to cahneg path of smartopz button
        // const googleLink = 'http://localhost:3001/';
        const googleLink = 'http://avanttec.in:3006/';
        // End of SOP-41
        window.open(googleLink, '_blank');
      };

      const handleIntopzButtonClick = () => {
        const googleLink = 'http://www.intopz.com/';
        window.open(googleLink, '_blank');
      };

      const handleLinkedinIconClick = () => {
        const googleLink = 'https://www.linkedin.com/company/smartopz/';
        window.open(googleLink, '_blank');
      };

      const handleYouTubeIconClick = () => {
        const googleLink = 'https://www.youtube.com/c/smartopz';
        window.open(googleLink, '_blank');
      };

      const handleFaceBookIconClick = () => {
        const googleLink = 'https://www.facebook.com/smartopz';
        window.open(googleLink, '_blank');
      };

  return (
    <Box 
    sx={{
        height:{ 
            lg:'25vh',
            xs:'90vh'
        },
        bgcolor:'#4169e1',
        color:'white'
        }}>
            <Box p={2} justifyContent={'center'} display={'flex'} >

                <Grid container justifyContent={'center'} display={'flex'} >
                  <Grid item lg={3} xs={12} sm={12} md={3} justifyContent={'flex-start'} display={'flex'}
                     sx={{
                        marginTop:{
                            xs:'1.7rem',
                            lg:0
                        }
                        }}>
                    <Box mt={2}>
                    <Box justifyContent={'center'}>
                        <Typography  fontFamily={'Segoe UI'}
                        fontSize={'1.5rem'} 
                        onClick={handleSmartopzButtonClick}
                        sx={{'&:hover': {color:'black'},cursor:'pointer'}}>
                        Smartopz
                        </Typography>
                        <Typography fontFamily={'Segoe UI'} mt={2} onClick={handleIntopzButtonClick} fontSize={'1.5rem'} sx={{'&:hover': {color:'black'},cursor:'pointer'}}>
                        Intopz Technologies
                        </Typography>
                    </Box>
                    
                    </Box>

                    </Grid>

                    <Grid item lg={3} xs={12} sm={12} md={3}display={'flex'} justifyContent={'center'}
                    sx={{
                        marginTop:{
                            xs:'1.7rem',
                            lg:0
                        },display:{
                            lg:'flex',
                            xs:'flex',
                            md:'flex',
                            xsl:'flex',
                        },
                        justifyContent:{
                            lg:'center',
                            xs:'flex-start',
                            md:'center',
                            xl:'center',
                        }
                        }}>
                        <Box mt={2}>
                        <Box >
                            <Box >
                            <Typography fontFamily={'Segoe UI'} mt={1} 
                            onClick={()=>{
                                navigate("/terms_and_conditions")
                            }}
                            variant='h7'  sx={{'&:hover': {color:'black'},cursor:'pointer'}}>
                            Terms & Conditions
                            </Typography>                        
                            </Box>
                            
                            <Box mt={2} >
                            <Typography fontFamily={'Segoe UI'} variant='h7'
                            onClick={()=>{
                                navigate("/privacy_policy")
                            }} 
                             sx={{'&:hover': {color:'black'},cursor:'pointer'}}>
                            Privacy Policy
                            </Typography>
                            </Box>

                            <Box mt={2} >
                            <Typography fontFamily={'Segoe UI'} mt={1} variant='h7'
                            onClick={()=>{
                                navigate("/refund_policy")
                            }}
                            sx={{'&:hover': {color:'black'},cursor:'pointer'}}>
                            Refund Policy
                            </Typography>  
                            </Box>
                        </Box>
                        </Box>                        
                    </Grid>

                    <Grid item lg={3} xs={12} sm={12} md={3}justifyContent={'flex-end'} display={'flex'}
                    sx={{
                        marginTop:{
                            xs:'1.7rem',
                            lg:0
                        },
                        display:{
                            lg:'flex',
                            xs:'flex',
                            md:'flex',
                            xsl:'flex',
                        },
                        justifyContent:{
                            lg:'flex-end',
                            xs:'flex-start',
                            md:'flex-end',
                            xl:'flex-end',
                        }
                        }}>
                        <Box mt={2}>
                            <Box mt={1} display={'flex'}
                            sx={{'&:hover': {
                                    color:'black',
                                    cursor:'pointer'
                                },}}>
                            <SmartphoneIcon/>
                            <Typography fontFamily={'Segoe UI'} ml={3} fontSize={'1rem'} >
                                9900694666
                            </Typography>
                            </Box>

                            <Box mt={1} display={'flex'}
                            sx={{'&:hover': {
                                    color:'black',
                                    cursor:'pointer'
                                },}}>
                            <WhatsAppIcon/>
                            <Typography fontFamily={'Segoe UI'} ml={3} fontSize={'1rem'} >
                            9900694666
                            </Typography>
                            </Box>
                            <Box mt={1} display={'flex'}
                            sx={{'&:hover': {
                                    color:'black',
                                    cursor:'pointer'
                                },}}>
                            <MailOutlineIcon/>
                            <Typography fontFamily={'Segoe UI'} ml={3} fontSize={'1rem'} >
                                support@smartopz.com
                            </Typography>
                            </Box>
                        </Box>                        
                    </Grid>
                    <Grid item lg={2}  xs={12} sm={12} md={3} justifyContent={'flex-end'} display={'flex'}
                    sx={{
                        marginTop:{
                            xs:'1.7rem',
                            lg:0
                        },
                        display:{
                            lg:'flex',
                            xs:'flex',
                            md:'flex',
                            xsl:'flex',
                        },
                        justifyContent:{
                            lg:'flex-end',
                            xs:'flex-start',
                            md:'flex-end',
                            xl:'flex-end',
                        },
                        ml:{
                            lg:5
                        }
                        }}>
                        <Box mt={2}>
                            <Box mt={1} display={'flex'} onClick={handleLinkedinIconClick}
                            sx={{'&:hover': {
                                    color:'black',
                                    cursor:'pointer'
                                },}}>
                            <LinkedInIcon/>
                            <Typography fontFamily={'Segoe UI'} ml={3} fontSize={'1rem'} >
                            LinkedIn
                            </Typography>
                            </Box>

                            <Box mt={1} display={'flex'} onClick={handleYouTubeIconClick}
                            sx={{'&:hover': {
                                    color:'black',
                                    cursor:'pointer'
                                },}}>
                            <YouTubeIcon/>
                            <Typography fontFamily={'Segoe UI'} ml={3} fontSize={'1rem'} >
                            YouTube
                            </Typography>
                            </Box>
                            <Box mt={1} display={'flex'} onClick={handleFaceBookIconClick}
                            sx={{'&:hover': {
                                    color:'black',
                                    cursor:'pointer'
                                },}}>
                            <FacebookIcon/>
                            <Typography fontFamily={'Segoe UI'} ml={3} fontSize={'1rem'} >
                            Facebook
                            </Typography>
                            </Box>
                        </Box>                        
                    </Grid>

                </Grid>

            </Box>
            

    </Box>
  );
};

export default Footer;